import React, { Component } from "react";
// import { Auth } from "aws-amplify";
import '../../assets/css/App.css';
import logo from '../../assets/images/logo.png';

export default class VerifyPage extends Component {
  
  handleVerification = event => {
    event.preventDefault();
    const { username, code } = this.props.inputs;
    console.log(username +"\t"+ code)
    // After retrieveing the confirmation code from the user
    // Auth.confirmSignUp(username, code, {
    //   // Optional. Force user confirmation irrespective of existing alias. By default set to True.
    //   forceAliasCreation: true
    // })
    //   .then(data => console.log(data))
    //   .then(()=>this.props.switchComponent("SignIn"))
    //   .catch(err => console.log(err));
  };

  render() {
    return (      
      <div className="login_page_container">
        <img src={logo} alt="Logo" className="login_page_logo"/>
        <form className="authentication__form">
          <div className="mb-4">
            <input type="text" name="code" value={this.props.code} placeholder="Verification Code" onChange={this.props.handleFormInput} className="login_page_input"/>
          </div>
          <div className="mb-4">
            <input type="submit" value="Submit Verification" onClick={this.handleVerification} className="login_page_button"/>
          </div>
        </form>
      </div>    
    );
  }
}