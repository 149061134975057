import React from "react";
import PropTypes from "prop-types";
import { Grid } from '@material-ui/core';
import { scaleLinear } from "d3-scale";
import { curveStep } from "d3-shape";
import { ChartCanvas, Chart } from "react-stockcharts";
import { AreaSeries } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { fitWidth } from "react-stockcharts/lib/helper";
import DepthHoverTooltip from "./marketdepthtooltip";
import StaticLine from "./marketdepthstaticline";
import HeadingWithInfo from "../general/headingwithinfo";
import "../../assets/css/main.css"

const marketdepthdescription = "Market depth is the breadth and depth of all open orders, both bids and asks for trading within an individual security. Typically, the more buy and sell orders that exist, the greater the depth of the market—provided that those orders are dispersed fairly evenly around the current market price of that security."

function getAccessorColor(d) {
    return d.type === "bid" ? "#00FF00" : "#FF0000";
}
  
function tooltipContent() {
    return ({ currentItem, xAccessor, displayXAccessor }) => {
        if (!currentItem.price) return null;
        return {
        x: xAccessor(currentItem),
        y: [
            {
            label: currentItem.type === "bids" ? "Buy" : "Sell",
            value: currentItem.totalVolume && currentItem.totalVolume,
            stroke: currentItem.type === "bids" ? "#FF0000" : "#00FF00",
            labelStroke: currentItem.type === "bid" ? "#FF0000" : "#00FF00",
            fontSize: 11
            },
            {
            label: "Cost",
            value:
                currentItem.totalVolume &&
                (currentItem.totalVolume * currentItem.price).toFixed(4),
            stroke: currentItem.type === "bids" ? "#FF0000" : "#00FF00",
            labelStroke: currentItem.type === "bids" ? "#FF0000" : "#00FF00",
            fontSize: 11
            }
        ]
        };
    };
}

class MarketDataChart extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = { 
        expanded: false,
           
      }
    }

    render() {
      // console.log(this.props.data)
      
      const { width, height, axisColor, type, ratio } = this.props;
      // const data = this.getDepthData()
      const data = this.props.data;
      const xScale = scaleLinear();        
      const xExtents = [0, data.length - 1];
      
      return (
        <React.Fragment>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <HeadingWithInfo heading="Market Depth Chart" headingclassname="edgeforecast_predheading" description={marketdepthdescription} />
            </Grid>
            <Grid item xs={12}>
              <ChartCanvas seriesName="Market Depth" ratio={ratio} width={width} height={height} margin={{ left: 0, right: 10, top: 0, bottom: 30 }}
                data={data} type={type} xAccessor={d => d['x']} xExtents={xExtents} xScale={xScale} panEvent={false} 
                zoomEvent={false} clamp={false} displayXAccessor={d => d['price']} >
                <Chart id={0} yExtents={d => d['totalVolume']}>
                  <XAxis axisAt="bottom" orient="bottom" tickStroke={axisColor} stroke={"transparent"} strokeWidth={0} 
                    tickFormat={d => (data[d]['price'] ? data[d]['price'].toString() : "")} zoomEnabled={false} ticks={5} />
                  <YAxis axisAt="right" orient="left" tickStroke={axisColor} stroke={"transparent"} strokeWidth={0} zoomEnabled={false} ticks={3} />
                  <YAxis axisAt="left" orient="right" tickStroke={axisColor} stroke={"transparent"} strokeWidth={0} zoomEnabled={false} ticks={3} />
                  <AreaSeries yAccessor={d => d['type'] === "bids" && d['price'] && d['totalVolume']} strokeWidth={2}
                    interpolation={curveStep} fill="#84F766" stroke={"#84F766"} />
                  <AreaSeries yAccessor={d => d['type'] === "asks" && d['price'] && d['totalVolume']} strokeWidth={2}
                    interpolation={curveStep} fill="#EF403C" stroke={"#EF403C"} />
                  <StaticLine />
                  <DepthHoverTooltip chartId={0} fontSize={15} fill={"#3d3d3d"} stroke={"#3d3d3d"}
                    fontFamily={"sans-serif"} fontFill={"#fff"} opacity={1} tooltipContent={tooltipContent()}
                    yAccessor={d => d['totalVolume']} lineStroke={getAccessorColor} r={5} />
                </Chart>
              </ChartCanvas>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }
}
MarketDataChart.propTypes = {
    data: PropTypes.array.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    ratio: PropTypes.number.isRequired,
    axisColor: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["svg", "hybrid"]).isRequired
};
  
MarketDataChart.defaultProps = {
    type: "hybrid",
    ratio: 1,
    axisColor: "#ffffff",
    height: 405
};
MarketDataChart = fitWidth(MarketDataChart);
  

export default MarketDataChart









