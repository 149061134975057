
const defaultdata = {

    defaultdata: {
        'chartdata': [
            {'bb': '', 'low': 0.0, 'date': 1622419200000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1622505600000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1622592000000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1622678400000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1622764800000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1622851200000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1622937600000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1623024000000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1623110400000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1623196800000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1623283200000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1623369600000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1623456000000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1623542400000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1623628800000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1623715200000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1623801600000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1623888000000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1623974400000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1624060800000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1624147200000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1624233600000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1624320000000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1624406400000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1624492800000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1624579200000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1624665600000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1624752000000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1624838400000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1624924800000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1625011200000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1625097600000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1625184000000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1625270400000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1625356800000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1625443200000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1625529600000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1625616000000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1625702400000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1625788800000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1625875200000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1625961600000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1626048000000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1626134400000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1626220800000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1626307200000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1626393600000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1626480000000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1626566400000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1626652800000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1626739200000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1626825600000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1626912000000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1626998400000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1627084800000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1627171200000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1627257600000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1627344000000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1627430400000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1627516800000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1627603200000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1627689600000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1627776000000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1627862400000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1627948800000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1628035200000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1628121600000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1628208000000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1628294400000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1628380800000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1628467200000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1628553600000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1628640000000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1628726400000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1628812800000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1628899200000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1628985600000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1629072000000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1629158400000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1629244800000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1629331200000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1629417600000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1629504000000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1629590400000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1629676800000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1629763200000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1629849600000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1629936000000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1630022400000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1630108800000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1630195200000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1630281600000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1630368000000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1630454400000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1630540800000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1630627200000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1630713600000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1630800000000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1630886400000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1630972800000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 },
            {'bb': '', 'low': 0.0, 'date': 1631059200000, 'high': 0.0, 'open': 0.0, 'close': 0.0, 'sma12': 0.0, 'sma72': 0.0, 'volume': 0.0 }
        ],
        'depthdata': {
            'asksbest': {'x': 0, 'type': 'ask', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
            'asksfull': [
                {'x': 0, 'type': 'ask', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'},
                {'x': 1, 'type': 'ask', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 2, 'type': 'ask', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 3, 'type': 'ask', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 4, 'type': 'ask', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 5, 'type': 'ask', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 6, 'type': 'ask', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 7, 'type': 'ask', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 8, 'type': 'ask', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 9, 'type': 'ask', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}
            ], 
            'bidsbest': {'x': 0, 'type': 'bid', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
            'bidsfull': [
                {'x': 0, 'type': 'bid', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 1, 'type': 'bid', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 2, 'type': 'bid', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 3, 'type': 'bid', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 4, 'type': 'bid', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 5, 'type': 'bid', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 6, 'type': 'bid', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 7, 'type': 'bid', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 8, 'type': 'bid', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 9, 'type': 'bid', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}
            ], 
            'chartdata': [
                {'x': 0, 'type': 'bid', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 1, 'type': 'bid', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 2, 'type': 'bid', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 3, 'type': 'bid', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 4, 'type': 'bid', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 5, 'type': 'bid', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 6, 'type': 'bid', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 7, 'type': 'bid', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 8, 'type': 'bid', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 9, 'type': 'bid', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 10, 'type': 'ask', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 11, 'type': 'ask', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 12, 'type': 'ask', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 13, 'type': 'ask', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 14, 'type': 'ask', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 15, 'type': 'ask', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 16, 'type': 'ask', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 17, 'type': 'ask', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 18, 'type': 'ask', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}, 
                {'x': 19, 'type': 'ask', 'price': 0.0, 'volume': 0.0, 'priceclassname': 'edgeforecast_md_data_bids'}
            ]
        }, 
        'widgetdata': {
            'tradespeed': [0.0], 
            'buyingpower': [0.0], 
            'tradedistribution': {'data': [0.2, 0.2, 0.2, 0.2, 0.2]}, 
            'volumedistribution': {'data': [0.2, 0.2, 0.2, 0.2, 0.2]},
            "volumeChanges": [{name: 'PositiveMoves',data: [0.5, 0, 0.5, 0, 0.5] }, { name: 'NegativeMoves', data: [0, -0.5, 0, -0.5, 0] }]
        },
        'generaldata': [
            {'value': 0.0, 'colour': '#dddddd', 'itemid': 'datedata'},

            {'value': 0.0, 'colour': '#dddddd', 'itemid': 'lastprice'}, 
            {'value': 0.0, 'colour': '#dddddd', 'itemid': 'change24Hr'}, 
            {'value': 0.0, 'colour': '#dddddd', 'itemid': 'high24Hr'}, 
            {'value': 0.0, 'colour': '#dddddd', 'itemid': 'low24Hr'}, 
            {'value': 0.0, 'colour': '#dddddd', 'itemid': 'totaltrades'}, 
            {'value': 0.0, 'colour': '#dddddd', 'itemid': 'value24Hr'},

            {'value': 0.0, 'colour': '#dddddd', 'itemid': 'volume24Hr'}, 
            {'value': 0.0, 'colour': '#dddddd', 'itemid': 'volumepermin'},
            {'value': 0.0, 'colour': '#dddddd', 'itemid': 'changepermin'}, 
            {'value': 0.0, 'colour': '#dddddd', 'itemid': 'tradespermin'}, 
            {'value': 0.0, 'colour': '#dddddd', 'itemid': 'rsi'}, 
            {'value': 0.0, 'colour': '#dddddd', 'itemid': 'macd'},

            {'value': 0.0, 'colour': '#dddddd', 'itemid': 'mfi'}, 
            {'value': 0.0, 'colour': '#dddddd', 'itemid': 'stoch'}, 
            {'value': 0.0, 'colour': '#dddddd', 'itemid': 'sharpe'}, 
            {'value': 0.0, 'colour': '#dddddd', 'itemid': 'sortino'}, 

            {'value': 0.0, 'colour': '#dddddd', 'itemid': 'volatility'}, 
            {'value': 0.0, 'colour': '#dddddd', 'itemid': 'beta'}
        ], 
        'forecastdata': {
            'accuracy': {
                'longterm': [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3], 
                'shortterm': [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3]
            }, 
            'forecasts': {
                'longterm': 0.0, 
                'shortterm': 0.0
            },
            'forecastability': 0
        }, 
        'displaychartdata': [
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1622419200, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1622505600, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1622592000, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1622678400, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1622764800, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1622851200, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1622937600, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1623024000, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1623110400, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1623196800, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1623283200, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1623369600, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1623456000, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1623542400, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1623628800, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1623715200, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1623801600, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1623888000, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1623974400, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1624060800, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1624147200, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1624233600, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1624320000, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1624406400, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1624492800, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1624579200, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1624665600, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1624752000, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1624838400, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1624924800, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1625011200, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1625097600, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1625184000, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1625270400, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1625356800, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1625443200, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1625529600, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1625616000, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1625702400, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1625788800, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1625875200, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1625961600, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1626048000, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1626134400, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1626220800, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1626307200, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1626393600, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1626480000, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1626566400, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1626652800, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1626739200, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1626825600, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1626912000, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1626998400, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1627084800, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1627171200, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1627257600, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1627344000, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1627430400, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1627516800, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1627603200, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1627689600, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1627776000, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1627862400, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1627948800, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1628035200, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1628121600, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1628208000, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1628294400, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1628380800, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1628467200, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1628553600, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1628640000, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1628726400, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1628812800, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1628899200, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1628985600, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1629072000, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1629158400, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1629244800, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1629331200, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1629417600, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1629504000, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1629590400, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1629676800, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1629763200, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1629849600, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1629936000, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1630022400, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1630108800, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1630195200, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1630281600, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1630368000, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1630454400, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1630540800, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1630627200, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1630713600, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1630800000, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1630886400, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1630972800, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0},
            {'mfi': 0.0, 'rsi': 0.0, 'beta': 0.0, 'macd': 0.0, 'stoch': 0.0, 'sharpe': 0.0, 'low24Hr': 0.0, 'sortino': 0.0, 'datedata': 1631059200, 'high24Hr': 0.0, 'lastprice': 0.0, 'value24Hr': 0.0, 'change24Hr': 0.0, 'volatility': 0.0, 'volume24Hr': 0.0, 'totaltrades': 0.0, 'changepermin': 0.0, 'tradespermin': 0.0, 'volumepermin': 0.0}
        ],        
    },
    defaultchartoptions: {
        mainchartoptions: {
          chart: {
            fontFamily: 'IBM Plex Sans, sans-serif',
            foreColor: "#dddddd",
            toolbar: {show: false},
            zoom: {enabled: false}         
          },
          legend: {show: false},
          tooltip: {enabled: false},
          xaxis: {type: 'datetime'},
          plotOptions: {
            boxPlot: {
                colors: {
                  upper: '#4648ff',
                  lower: '#4648ff'
                }
              },
            candlestick: {
              colors: {
                upward: '#00B746',
                downward: '#EF403C'
              },
              wick: {useFillColor: true}
            },            
          },
          stroke: {
            width: 1,
            curve: 'smooth',
            colors: ['#4648ff','#4648ff', "#4648ff","#4648ff", "#4648ff","#4648ff"]
          },
        },
        edgechartoptions: {
            charttype: "hybrid",        
            axisappearance: {
                stokecolour: "#dddddd",
                stokeopacity: 0.5

            },
            mavs: {
                shortmavcolour: "#4648ff",
                longmavcolour: "#dddddd",
            },
            candlesappearance: {
                wickStroke:  function fill(d) {
                    return d.close > d.open ? "#00B746" : "#EF403C";
                },
                fill: function fill(d) {
                    return d.close > d.open ? "#00B746" : "#EF403C";
                },
                stroke: function fill(d) {
                    return d.close > d.open ? "#00B746" : "#EF403C";
                },
                candleStrokeWidth: 1,
                widthRatio: 0.8,
                opacity: 1,
            },
            forecastappearance: {
                stroke: {
                    top: "#272833",
                    middle: "#272833",
                    bottom: "#272833",
                },
                fill: "#4648ff",
                forecastopacity: 0.6
            }
        },
        dataplotoptions: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                  enabled: false
                }
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'straight'
              },              
              grid: {
                row: {
                  colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                  opacity: 0.5
                },
              },
              xaxis: {
                categories: [new Date(1538884800000),
                    new Date(1538883000000),
                    new Date(1538881200000),
                    new Date(1538879400000),
                    new Date(1538877600000),
                    new Date(1538875800000),
                    new Date(1538874000000),
                    new Date(1538872200000),
                    new Date(1538870400000),
                    new Date(1538868600000),
                    new Date(1538866800000),
                    new Date(1538865000000),
                    new Date(1538863200000),
                    new Date(1538861400000),
                    new Date(1538859600000),
                    new Date(1538857800000),
                    new Date(1538856000000),
                    new Date(1538854200000),
                    new Date(1538852400000),
                    new Date(1538850600000),
                    new Date(1538848800000),
                    new Date(1538847000000),
                    new Date(1538845200000),
                    new Date(1538843400000),
                    new Date(1538841600000),
                    new Date(1538839800000),
                    new Date(1538838000000),
                    new Date(1538836200000),
                    new Date(1538834400000),
                    new Date(1538832600000),
                    new Date(1538830800000),
                    new Date(1538829000000),
                    new Date(1538827200000),
                    new Date(1538825400000),
                    new Date(1538823600000),
                    new Date(1538821800000),
                    new Date(1538820000000),
                    new Date(1538818200000),
                    new Date(1538816400000),
                    new Date(1538814600000),
                    new Date(1538812800000),
                    new Date(1538811000000),
                    new Date(1538809200000),
                    new Date(1538807400000),
                    new Date(1538805600000),
                    new Date(1538803800000),
                    new Date(1538802000000),
                    new Date(1538800200000),
                    new Date(1538798400000),
                    new Date(1538796600000),
                    new Date(1538794800000),
                    new Date(1538793000000),
                    new Date(1538791200000),
                    new Date(1538789400000),
                    new Date(1538787600000),
                    new Date(1538785800000),
                    new Date(1538784000000),
                    new Date(1538782200000),
                    new Date(1538780400000),
                    new Date(1538778600000)],
              }
        },
        accuracychartoptions: {
          chart: {    
            type: 'heatmap',
            toolbar: {show: false},
            redrawOnWindowResize: true
          },
          plotOptions: {
            heatmap: {
              shadeIntensity: 0.1,
              radius: 0,
              useFillColorAsStroke: false,
              colorScale: {
                ranges: [{
                    from: 0,
                    to: 2,
                    name: 'incorrect',
                    color: '#EF403C'
                  },
                  {
                    from: 2,
                    to: 4,
                    name: 'correct',
                    color: '#00B746'
                  }
                ]
              }
            }
          },
          dataLabels: {enabled: false},
          stroke: {width: 1},
          tooltip: {enabled: false },
          legend: {show: false},  
          xaxis: {labels:{show:false},axisTicks: {show: false}},
          yaxis: {labels:{show:false},axisTicks: {show: false}},
        },
        pricedistributionoptions: {
          chart: {      
            type: 'bar',
            toolbar: {show: false}
          },    
          fill: {opacity: 1},
          stroke: {
            width: 1,
            colors: ["#ffffff"]
          },
          dataLabels: {
            enabled: false
          },
          colors: ["#4648ff", "#4142eb","#3839cf", "#2d2eb1","#2d2e8f"],
          yaxis: {
            labels: {
                show: true,                
                style: {
                    colors: ["#dddddd"]
                }
            },
            axisTicks: {
                show: true,
            }
        },
          xaxis: {
            categories: ['Bar Top', 'Bar TopMid', 'Bar Mid', 'Bar MidBottom', 'Bar Bottom'],
            max: 100,
            labels: {
                show: false,                
                style: {
                    colors: ["#dddddd"]
                }
            },
            axisTicks: {
                show: false,
            }
          },
          legend: {show: false},
          tooltip: {
            enabled: false,
            // theme: 'dark',
            // x: {
            //   show: false
            // },
            // y: {
            //   title: {
            //     formatter: function () {
            //       return ''
            //     }
            //   }
            // }
          },
          plotOptions: {
            bar: {
                barHeight: '100%',
                distributed: true,
                borderRadius: 2,
                horizontal: true,
            }
          }
        },
        strengthchartoptions: {
            arcsLength:[0.5, 0.5],
            colors:["#4648ff", "#dddddd"],
            arcWidth: 0.1        
        },
        tradespeedoptions: {            
             arcsLength:[0.6, 0.2, 0.2],
             colors:["#4648ff", "#3839cf","#2d2e8f"],
             arcWidth: 0.1
        },
        tradedistributionoptions: {
            chart: {
                type: 'bar',
                toolbar: { show: false }
              },
              plotOptions: {
                bar: {
                  borderRadius: 3,
                  horizontal: true,
                }
              },
              grid: {
                show: false,                
                xaxis: { lines: { show: false } },   
                yaxis: { lines: { show: false } },                  
                padding: { top: -20, right: -10, bottom: 0, left: 0 },  
                },
                colors:["#4648ff"],
              tooltip: {enabled: false},
                dataLabels: {enabled: false},
              xaxis: {
                categories: ['High', 'MidHigh', 'Mid', 'MidLow', 'Low'],  
                max: 50, axisBorder: {show: false},axisTicks: {show: false},labels: {show: false}},
              yaxis: {axisBorder: {show: false},axisTicks: {show: false},labels: {show: true},
                        labels: {show: true, align: 'right', style: {colors: ['#dddddd'], fontSize: '1vmin'}
                    }}
        },
        radialwidgetoptionsSml: {
            chart: {        
              type: 'radialBar',
              toolbar: {
                show: false
              },
              offsetY: -10
            },
            colors: ['#4648ff'],
            plotOptions: {
              radialBar: {
                startAngle: -135,
                endAngle: 135,
                hollow: {
                  margin: 1,
                  size: '70%',
                  background: 'transparent',
                  image: undefined,
                  imageOffsetX: 0,
                  imageOffsetY: 0,
                  position: 'front',
                  dropShadow: {
                    enabled: true,
                    top: 3,
                    left: 0,
                    blur: 4,
                    opacity: 0.24
                  }
                },
                track: {
                  background: '#707070',
                  strokeWidth: '70%',
                  margin: 0, // margin is in pixels
                  dropShadow: {
                    enabled: false,
                    top: -3,
                    left: 0,
                    blur: 0,
                    opacity: 0.35
                  }
                },
            
                dataLabels: {
                  show: true,
                  name: {
                    offsetY: -5,
                    show: true,
                    color: '#dddddd',
                    fontSize: '1.5vmin'
                  },
                  value: {
                    offsetY: -10,
                    formatter: function(val) {
                      return val + "%";
                    },
                    color: '#dddddd',
                    fontSize: '2vmin',
                    show: true,
                  }
                }
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                  shade: 'dark',
                  shadeIntensity: 0.05,
                  inverseColors: false,
                  // gradientToColors: ['#00B746'],
                  opacityFrom: 1,
                  opacityTo: 1,
                  stops: [0, 10, 20, 30 ,40 ,50, 60, 70, 80 ,90, 100]
              },
            },
            stroke: {
              dashArray: 1
            },
            labels: [' '],
        },
        radialwidgetoptionsLrg: {
            chart: {        
              type: 'radialBar',
              toolbar: {
                show: false
              },
              offsetY: -10
            },
            colors: ['#4648ff'],
            plotOptions: {
              radialBar: {
                startAngle: -135,
                endAngle: 135,
                hollow: {
                  margin: 1,
                  size: '70%',
                  background: 'transparent',
                  image: undefined,
                  imageOffsetX: 0,
                  imageOffsetY: 0,
                  position: 'front',
                  dropShadow: {
                    enabled: true,
                    top: 3,
                    left: 0,
                    blur: 4,
                    opacity: 0.24
                  }
                },
                track: {
                  background: '#707070',
                  strokeWidth: '70%',
                  margin: 0, // margin is in pixels
                  dropShadow: {
                    enabled: false,
                    top: -3,
                    left: 0,
                    blur: 0,
                    opacity: 0.35
                  }
                },
            
                dataLabels: {
                  show: true,
                  name: {
                    offsetY: -5,
                    show: true,
                    color: '#dddddd',
                    fontSize: '1.5vmin'
                  },
                  value: {
                    offsetY: -10,
                    formatter: function(val) {
                      return val + "%";
                    },
                    color: '#dddddd',
                    fontSize: '1.25vmin',
                    show: true,
                  }
                }
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                  shade: 'dark',
                  shadeIntensity: 0.05,
                  inverseColors: false,
                  // gradientToColors: ['#00B746'],
                  opacityFrom: 1,
                  opacityTo: 1,
                  stops: [0, 10, 20, 30 ,40 ,50, 60, 70, 80 ,90, 100]
              },
            },
            stroke: {
              dashArray: 1
            },
            labels: [' '],
        },
        gaugewidgetoptionsSml: {
            chart: {        
              type: 'radialBar',
              toolbar: {
                show: false
              },
              offsetY: -10
            },
            colors: ["#4648ff", "#3839cf","#2d2e8f"],
            plotOptions: {
              radialBar: {
                startAngle: -90,
                endAngle: 90,
                hollow: {
                  margin: 1,
                  size: '70%',
                  background: 'transparent',
                  image: undefined,
                  imageOffsetX: 0,
                  imageOffsetY: 0,
                  position: 'front',
                  dropShadow: {
                    enabled: true,
                    top: 3,
                    left: 0,
                    blur: 4,
                    opacity: 0.24
                  }
                },
                track: {
                  background: '#707070',
                  strokeWidth: '70%',
                  margin: 0, // margin is in pixels
                  dropShadow: {
                    enabled: false,
                    top: -3,
                    left: 0,
                    blur: 0,
                    opacity: 0.35
                  }
                },
            
                dataLabels: {
                  show: true,
                  name: {
                    offsetY: -5,
                    show: true,
                    color: '#dddddd',
                    fontSize: '1.5vmin'
                  },
                  value: {
                    offsetY: 0,
                    formatter: function(val) {
                      return val + "% mx/tpm";
                    },
                    color: '#dddddd',
                    fontSize: '1vmin',
                    show: true,
                  }
                }
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                  shade: 'dark',
                  shadeIntensity: 0.05,
                  inverseColors: false,
                  // gradientToColors: ['#00B746'],
                  opacityFrom: 1,
                  opacityTo: 1,
                  stops: [0, 10, 20, 30 ,40 ,50, 60, 70, 80 ,90, 100]
              },
            },
            stroke: {
              dashArray: 1
            },
            labels: [' '],
        },
        gaugewidgetoptionsLrg: {
            chart: {        
              type: 'radialBar',
              toolbar: {
                show: false
              },
              offsetY: -10
            },
            colors: ["#4648ff", "#3839cf","#2d2e8f"],
            plotOptions: {
              radialBar: {
                startAngle: -90,
                endAngle: 90,
                hollow: {
                  margin: 1,
                  size: '70%',
                  background: 'transparent',
                  image: undefined,
                  imageOffsetX: 0,
                  imageOffsetY: 0,
                  position: 'front',
                  dropShadow: {
                    enabled: true,
                    top: 3,
                    left: 0,
                    blur: 4,
                    opacity: 0.24
                  }
                },
                track: {
                  background: '#707070',
                  strokeWidth: '70%',
                  margin: 0, // margin is in pixels
                  dropShadow: {
                    enabled: false,
                    top: -3,
                    left: 0,
                    blur: 0,
                    opacity: 0.35
                  }
                },
            
                dataLabels: {
                  show: true,
                  name: {
                    offsetY: -5,
                    show: true,
                    color: '#dddddd',
                    fontSize: '1.5vmin'
                  },
                  value: {
                    offsetY: 0,
                    formatter: function(val) {
                      return val + "% mx/tpm";
                    },
                    color: '#dddddd',
                    fontSize: '1.25vmin',
                    show: true,
                  }
                }
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                  shade: 'dark',
                  shadeIntensity: 0.05,
                  inverseColors: false,
                  // gradientToColors: ['#00B746'],
                  opacityFrom: 1,
                  opacityTo: 1,
                  stops: [0, 10, 20, 30 ,40 ,50, 60, 70, 80 ,90, 100]
              },
            },
            stroke: {
              dashArray: 1
            },
            labels: [' '],
        },
        volumechangesoptionsSml: {
            chart: {
              type: 'bar',              
              stacked: true,
              toolbar: { show: false }
            },
            colors: ['#4648ff'],
            plotOptions: {
              bar: {
                horizontal: true,
                barHeight: '70%',
              },
            },            
            stroke: {
                show: false,
            //   width: 1,
            //   colors: ["#fff"]
            },            
            grid: {
              xaxis: {lines: {show: false}},
              yaxis: {lines: {show: false}},
              padding: { top: -20, right: 0, bottom: 0, left: 0 },  
            },           
            tooltip: {
              shared: false,
              x: {
                formatter: function (val) {
                  return val
                }
              },
              y: {
                formatter: function (val) {
                  return Math.abs(val) + "%"
                }
              }
            }, 
            xaxis: {categories: ['Bar -1', 'Bar -2', 'Bar -3', 'Bar -4', 'Bar -5'], axisBorder: {show: false},axisTicks: {show: false},labels: {show: false}},
            yaxis: {axisBorder: {show: false},axisTicks: {show: false},labels: {show: true, align: 'right', style: {colors: ['#dddddd'], fontSize: '1.25vmin'}}, min: -5, max: 5},                       
            tooltip: {enabled: false},
            dataLabels: {enabled: false},
            fill: {opacity: 1},
            legend: {show: false}
        },
        volumechangesoptionsLrg: {
            chart: {
              type: 'bar',              
              stacked: true,
              toolbar: { show: false }
            },
            colors: ['#4648ff'],
            plotOptions: {
              bar: {
                horizontal: true,
                barHeight: '70%',
              },
            },            
            stroke: {
                show: false,
            //   width: 1,
            //   colors: ["#fff"]
            },            
            grid: {
              xaxis: {lines: {show: false}},
              yaxis: {lines: {show: false}},
              padding: { top: -20, right: 0, bottom: 0, left: 0 },  
            },           
            tooltip: {
              shared: false,
              x: {
                formatter: function (val) {
                  return val
                }
              },
              y: {
                formatter: function (val) {
                  return Math.abs(val) + "%"
                }
              }
            }, 
            xaxis: {categories: ['Bar -1', 'Bar -2', 'Bar -3', 'Bar -4', 'Bar -5'], axisBorder: {show: false},axisTicks: {show: false},labels: {show: false}},
            yaxis: {axisBorder: {show: false},axisTicks: {show: false},labels: {show: true, align: 'right', style: {colors: ['#dddddd'], fontSize: '1vmin'}}, min: -5, max: 5},                       
            tooltip: {enabled: false},
            dataLabels: {enabled: false},
            fill: {opacity: 1},
            legend: {show: false}
        },
        indicatorchartoptions: {
            'mfi': {
                'charttype':'singleline',
                'yaxisextent': [90,10],
                 'yaxisticks':[20,80]
                }, 
            'rsi': {
                'charttype':'singleline',
                'yaxisextent': [80,20],
                 'yaxisticks':[30,70]
                }, 
            'beta': {
                'charttype':'barchart',
                'yaxisextent': [(d)=>d.close],
                 'yaxisticks':[(d)=>d.close]
                }, 
            'macd': {
                'charttype':'macd',
                'yaxisextent': [80,20],
                 'yaxisticks':[-0.2,0.2]
                }, 
            'stoch': {
                'charttype':'singleline',
                'yaxisextent': [100,0],
                 'yaxisticks':[20,80]
                }, 
            'sharpe': {
                'charttype':'barchart',
                'yaxisextent': [(d)=>d.close],
                 'yaxisticks':[(d)=>d.close]
                }, 
            'low24Hr': {
                'charttype':'singleline',
                'yaxisextent': [(d)=>d.close],
                 'yaxisticks':[0]
                },
            'sortino': {
                'charttype':'barchart',
                'yaxisextent': [(d)=>d.close],
                 'yaxisticks':[(d)=>d.close]
                }, 
            'high24Hr': {
                'charttype':'singleline',
                'yaxisextent': [(d)=>d.close],
                 'yaxisticks':[0]
                },
            'lastprice': {
                'charttype':'singleline',
                'yaxisextent': [(d)=>d.close],
                 'yaxisticks':[]
                }, 
            'value24Hr': {
                'charttype':'barchart',
                'yaxisextent': [(d)=>d.close],
                 'yaxisticks':[(d)=>d.close]
                }, 
            'change24Hr': {
                'charttype':'singleline',
                'yaxisextent': [(d)=>d.close],
                 'yaxisticks':[0]
                }, 
            'volatility': {
                'charttype':'singleline',
                'yaxisextent': [(d)=>d.close],
                 'yaxisticks':[0]
                }, 
            'volume24Hr': {
                'charttype':'barchart',
                'yaxisextent': [(d)=>d.close],
                 'yaxisticks':[(d)=>d.close]
                }, 
            'totaltrades': {
                'charttype':'barchart',
                'yaxisextent': [(d)=>d.close],
                 'yaxisticks':[(d)=>d.close]
                }, 
            'changepermin': {
                'charttype':'barchart',
                'yaxisextent': [(d)=>d.close],
                 'yaxisticks':[(d)=>d.close]
                }, 
            'tradespermin': {
                'charttype':'barchart',
                'yaxisextent': [(d)=>d.close],
                 'yaxisticks':[(d)=>d.close]
                },  
            'volumepermin': {
                'charttype':'barchart',
                'yaxisextent': [(d)=>d.close],
                 'yaxisticks':[(d)=>d.close]
                }, 
        }
    },
    defaultdescriptions: {
        marketdepth_bestbids: "Best Bids is the best available bid (price and volume) at the time of the last price value. ",
        marketdepth_bestasks: "Best Asks is the best available ask (price and volume) at the time of the last price value. ",
        tradeDistDesc: "Trades Distribution is the distribution of trades within the price range. A larger percentage in Bar Top would indicate strong buying, where conversely a larger percentage in Bar Bottom would indicate strong selling. ",
        buyingStrengthDesc: "Buying Strength is a proprietary Edgeforecaster algorithm utilising many popular technical oscillating indicators. Where buying power is low, there has been a greater amount of selling in recent past. Where buying power is high, there has been a greater amount of buying. ",
        tradespermin: "Trades per Min is the average number of trades executed on the exchange per minute. Generally, the higher the value, the higher the probability of a price move. ",
        pricechartdescription: "A price chart displays the price information of a security, aggregated into a specific time period.",
        lastprice: {
            heading: "Last Price",
            description: "Last Price is the last traded price for the preceeding 24Hr period."
        },
        change24Hr: {
            heading: "24Hr Change",
            description: "24Hr Change is the percentage change in price for the preceeding 24Hr period."
        },
        high24Hr: {
            heading: "24Hr High",
            description: "24Hr High is the highest traded price for the preceeding 24Hr period."
        },
        low24Hr: {
            heading: "24Hr Low",
            description: "24Hr Low is the lowest traded price for the preceeding 24Hr period."
        },
        totaltrades: {
            heading: "Total Trades",
            description: "Total Trades is the total number of trades for the preceeding 24Hr period."
        },
        beta: {
            heading: "Beta",
            description: "Last Price is the last traded price for the preceeding 24Hr period."
        },
        value24Hr: {
            heading: "Value 24Hr",
            description: "Last Price is the last traded price for the preceeding 24Hr period."
        },
        volume24Hr: {
            heading: "Volume 24Hr",
            description: "Last Price is the last traded price for the preceeding 24Hr period."
        },
        volumepermin: {
            heading: "Vol p/min",
            description: "Last Price is the last traded price for the preceeding 24Hr period."
        },
        changepermin: {
            heading: "%Chg p/min",
            description: "Last Price is the last traded price for the preceeding 24Hr period."
        },
        tradespermin: {
            heading: "Trades p/min",
            description: "Last Price is the last traded price for the preceeding 24Hr period."
        },
        sharpe: {
            heading: "Sharp",
            description: "Last Price is the last traded price for the preceeding 24Hr period."
        },
        rsi: {
            heading: "RSI",
            description: "The relative strength index (RSI) is a momentum indicator which measures the magnitude of recent price changes to evaluate overbought or oversold conditions."
        },
        macd: {
            heading: "MACD",
            description: "Last Price is the last traded price for the preceeding 24Hr period."
        },
        mfi: {
            heading: "MFI",
            description: "Last Price is the last traded price for the preceeding 24Hr period."
        },
        stoch: {
            heading: "Stochactic",
            description: "Last Price is the last traded price for the preceeding 24Hr period."
        },
        volatility: {
            heading: "Volatility",
            description: "Last Price is the last traded price for the preceeding 24Hr period."
        },
        sortino: {
            heading: "Sortino",
            description: "Last Price is the last traded price for the preceeding 24Hr period."
        }
    }
};

export default defaultdata;


