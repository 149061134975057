import React from "react";
import { Helmet } from 'react-helmet'
import { Checkbox, Grid } from '@material-ui/core';
import SearchListItem from "../edgeforecaster/searchlistitem";
import { ImMinus, ImList, ImCross} from "react-icons/im";
import Spinner from 'react-spinner-material';
import "react-pro-sidebar/dist/css/styles.css";
import "../../assets/css/main.css"
import searchitem from "../../assets/images/search.png"


class SymbolSearch extends React.Component { 
  constructor(props) {
    super(props);
    this.state = { 
      usersettings: null,
      loading: false,
      filters: false,
      filterselections: {
        cryptocurrencies: true,
        forex: true,
        equities: true,
        futures: true,
        others: true,
        shorttermpos: true,
        shorttermneg: true,
        longtermpos: true,
        longtermneg: true,
        forecastability90: true,
        forecastability75: true,
        forecastability50: true,
        forecastability25: true,
        forecastability0: true,
        volatility10: true,
        volatility0: true,
        aboveavgvol: true,
        buyingpower50: true,
        buyingpower0: true
      }
    }
    this.togglefilters = this.togglefilters.bind(this);
    this.togglecheckboxcolor = this.togglecheckboxcolor.bind(this);
    this.togglecheckbox = this.togglecheckbox.bind(this);
  }

  togglecheckboxcolor(checked) {
    if(checked){
      return "#4648ff"
    } else { return "#dddddd"}

  }
  togglecheckbox(name) {
    var selected = this.state.filterselections[name]    
    var oldState = this.state;
    var oldFilterSelections = this.state.filterselections;
    oldFilterSelections[name] = !selected;
    this.setState({
      ...oldState,
      filterselections: oldFilterSelections
    })        
  }
  togglefilters(){
    if(this.state.filters){
      this.setState({filters:false})
    } else { this.setState({filters:true}) }
  }  
  componentDidMount() {
    
  }
  
  render() {    
    const siteTitle = 'EdgeSymbol Search'
    const isLoading = false;    
    console.log(this.props)
    return (
      <React.Fragment>
        <Helmet>
          <title>EdgeForecaster - {siteTitle}</title>
          <meta name="description" content="EdgeForecaster" />
        </Helmet>
        <div id="main" className="wrapper style1">
          <div className="container">          
            <div className="row gtr-150">            
              <div className="col-12 col-12-medium imp-medium">
                <section id="content">                  
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <h1>EdgeSymbol Search</h1>                      
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>                    
                    <Grid item xs={8}>
                      {this.state.filters ? 
                        <Grid container spacing={0} className="settings_filtercontainer">
                          <Grid item xs={11}>
                            <Grid container spacing={0}>
                              <Grid item xs={3}>
                                <Grid container spacing={0}>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_catheading">Symbol Type</div>                                    
                                  </Grid>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_itemheading" >
                                      <Checkbox checked={this.state.filterselections.cryptocurrencies}
                                        onClick={() => this.togglecheckbox("cryptocurrencies")} 
                                        style={{color:this.togglecheckboxcolor(this.state.filterselections.cryptocurrencies), float:"left"}} />
                                      Cryptocurrencies
                                    </div>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_itemheading" >
                                      <Checkbox checked={this.state.filterselections.forex}
                                        onClick={() => this.togglecheckbox("forex")} 
                                        style={{color:this.togglecheckboxcolor(this.state.filterselections.forex), float:"left"}} />
                                      Forex
                                    </div>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_itemheading" >
                                      <Checkbox checked={this.state.filterselections.equities}
                                        onClick={() => this.togglecheckbox("equities")} 
                                        style={{color:this.togglecheckboxcolor(this.state.filterselections.equities), float:"left"}} />
                                      Equities
                                    </div>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_itemheading" >
                                      <Checkbox checked={this.state.filterselections.futures}
                                        onClick={() => this.togglecheckbox("futures")} 
                                        style={{color:this.togglecheckboxcolor(this.state.filterselections.futures), float:"left"}} />
                                      Futures
                                    </div>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_itemheading" >
                                      <Checkbox checked={this.state.filterselections.others}
                                        onClick={() => this.togglecheckbox("others")} 
                                        style={{color:this.togglecheckboxcolor(this.state.filterselections.others), float:"left"}} />
                                      Others                                      
                                    </div>
                                  </Grid>                          
                                </Grid>
                              </Grid>
                              <Grid item xs={3}>
                                <Grid container spacing={0}>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_catheading">Forecast %</div>                                    
                                  </Grid>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_itemheading" >
                                      <Checkbox checked={this.state.filterselections.shorttermpos}
                                        onClick={() => this.togglecheckbox("shorttermpos")} 
                                        style={{color:this.togglecheckboxcolor(this.state.filterselections.shorttermpos), float:"left"}} />
                                      Shortterm Pos
                                    </div>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_itemheading" >
                                      <Checkbox checked={this.state.filterselections.shorttermneg}
                                        onClick={() => this.togglecheckbox("shorttermneg")} 
                                        style={{color:this.togglecheckboxcolor(this.state.filterselections.shorttermneg), float:"left"}} />
                                      Shortterm Neg
                                    </div>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_itemheading" >
                                      <Checkbox checked={this.state.filterselections.longtermpos}
                                        onClick={() => this.togglecheckbox("longtermpos")} 
                                        style={{color:this.togglecheckboxcolor(this.state.filterselections.longtermpos), float:"left"}} />
                                      Longterm Pos
                                    </div>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_itemheading" >
                                      <Checkbox checked={this.state.filterselections.longtermneg}
                                        onClick={() => this.togglecheckbox("longtermneg")} 
                                        style={{color:this.togglecheckboxcolor(this.state.filterselections.longtermneg), float:"left"}} />
                                      Longterm Neg
                                    </div>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_itemheading" />                                                                          
                                  </Grid>                          
                                </Grid>
                              </Grid>
                              <Grid item xs={3}>
                                <Grid container spacing={0}>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_catheading">Forecastability</div>                                    
                                  </Grid>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_itemheading" >
                                      <Checkbox checked={this.state.filterselections.forecastability90}
                                        onClick={() => this.togglecheckbox("forecastability90")} 
                                        style={{color:this.togglecheckboxcolor(this.state.filterselections.forecastability90), float:"left"}} />
                                      &#62; 90                                      
                                    </div>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_itemheading" >
                                      <Checkbox checked={this.state.filterselections.forecastability75}
                                        onClick={() => this.togglecheckbox("forecastability75")} 
                                        style={{color:this.togglecheckboxcolor(this.state.filterselections.forecastability75), float:"left"}} />
                                      75 - 90                                      
                                    </div>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_itemheading" >
                                      <Checkbox checked={this.state.filterselections.forecastability50}
                                        onClick={() => this.togglecheckbox("forecastability50")} 
                                        style={{color:this.togglecheckboxcolor(this.state.filterselections.forecastability50), float:"left"}} />
                                      50 - 75                                      
                                    </div>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_itemheading" >
                                      <Checkbox checked={this.state.filterselections.forecastability25}
                                        onClick={() => this.togglecheckbox("forecastability25")} 
                                        style={{color:this.togglecheckboxcolor(this.state.filterselections.forecastability25), float:"left"}} />
                                      25 - 50                                      
                                    </div>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_itemheading" >
                                      <Checkbox checked={this.state.filterselections.forecastability0}
                                        onClick={() => this.togglecheckbox("forecastability0")} 
                                        style={{color:this.togglecheckboxcolor(this.state.filterselections.forecastability0), float:"left"}} />
                                      &#60; 25                                      
                                    </div>
                                  </Grid>                          
                                </Grid>
                              </Grid>
                              <Grid item xs={3}>
                                <Grid container spacing={0}>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_catheading">Behaviour</div>                                    
                                  </Grid>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_itemheading" >
                                      <Checkbox checked={this.state.filterselections.volatility10}
                                        onClick={() => this.togglecheckbox("volatility10")} 
                                        style={{color:this.togglecheckboxcolor(this.state.filterselections.volatility10), float:"left"}} />
                                      Volatility &#62; 10%
                                    </div>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_itemheading" >                                      
                                      <Checkbox checked={this.state.filterselections.volatility0}
                                        onClick={() => this.togglecheckbox("volatility0")} 
                                        style={{color:this.togglecheckboxcolor(this.state.filterselections.volatility0), float:"left"}} />
                                      Volatility &#60; 10%
                                    </div>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_itemheading" >
                                      <Checkbox checked={this.state.filterselections.aboveavgvol}
                                        onClick={() => this.togglecheckbox("aboveavgvol")} 
                                        style={{color:this.togglecheckboxcolor(this.state.filterselections.aboveavgvol), float:"left"}} />
                                      Volume Above Avg                                      
                                    </div>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_itemheading" >
                                      <Checkbox checked={this.state.filterselections.buyingpower50}
                                        onClick={() => this.togglecheckbox("buyingpower50")} 
                                        style={{color:this.togglecheckboxcolor(this.state.filterselections.buyingpower50), float:"left"}} />
                                      Buying Power &#62; 50%                                      
                                    </div>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <div className="settings_filtercontainer_itemheading" >
                                      <Checkbox checked={this.state.filterselections.buyingpower0}
                                        onClick={() => this.togglecheckbox("buyingpower0")} 
                                        style={{color:this.togglecheckboxcolor(this.state.filterselections.buyingpower0), float:"left"}} />
                                      Buying Power &#60; 50%
                                    </div>
                                  </Grid>                          
                                </Grid>
                              </Grid>
                            </Grid>                      
                          </Grid>
                          <Grid item xs={1} className="settings_filtercontainer_close" onClick={this.togglefilters}>
                            <ImCross />
                          </Grid>
                        </Grid>
                      :
                        <Grid container spacing={0}>
                          <Grid item xs={1}>
                            <div className="settings_searchfilterbutton" onClick={this.togglefilters}>
                              <ImList />
                              Filters
                            </div>
                          </Grid>
                          <Grid item xs={11}>                            
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                    <Grid item xs={4}>
                      <form className="settings_searchform">
                        <input type="search" className="settings_searchfield" />                      
                        <button type="submit" className="settings_searchbutton">
                          <img src={searchitem} />
                          </button>                          
                      </form>
                    </Grid>
                    <Grid item xs={12} className="settings_symbollist">
                      <div className="table-container">
                        <div className="uk-overflow-auto">
                          <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
                            <tbody>
                              <tr>
                                <th>
                                <Grid container spacing={0}>
                                  <Grid item xs={2} className="settings_searchtable_headings" style={{textAlign:"start"}} >Name</Grid>
                                  <Grid item xs={1} className="settings_searchtable_headings" > </Grid>
                                  <Grid item xs={1} className="settings_searchtable_headings" >Last Price</Grid>
                                  <Grid item xs={1} className="settings_searchtable_headings" >24hr %Change</Grid>
                                  <Grid item xs={1} className="settings_searchtable_headings" >Trades/min</Grid>
                                  <Grid item xs={1} className="settings_searchtable_headings" > </Grid>
                                  <Grid item xs={2} className="settings_searchtable_headings" >Forecastability</Grid>
                                  <Grid item xs={1} className="settings_searchtable_headings" >Shortterm</Grid>
                                  <Grid item xs={1} className="settings_searchtable_headings" >Longterm</Grid>
                                  <Grid item xs={1} className="settings_searchtable_headings" >Selected </Grid>
                                </Grid>
                                </th>
                              </tr>
                            {isLoading ?
                              <tr><td colSpan={6} className="uk-text-center"><em className="uk-text-muted">Loading...</em></td></tr>
                            :                                   
                              this.props.usersettings.map((settingdata, index) => {
                                return (
                                <tr key={index} >
                                  <td>
                                    <SearchListItem searchitem={settingdata} />                                        
                                  </td>
                                  </tr>)
                                  })
                              }
                              </tbody>
                          </table>
                        </div>
                        </div>
                    </Grid>
                  </Grid>
                </section>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );    
  }
}

export default SymbolSearch