import React, { Component } from "react";
import PropTypes from "prop-types";
import GenericComponent from "react-stockcharts/lib/GenericComponent";
import { isNotDefined } from "react-stockcharts/lib/utils";

class StaticLine extends Component {
  constructor(props) {
    super(props);
    this.renderSVG = this.renderSVG.bind(this);
    this.drawOnCanvas = this.drawOnCanvas.bind(this);
  }
  drawOnCanvas(ctx, moreProps) {
    const point = helper(this.props, moreProps);
    if (isNotDefined(point)) return;
    const { stroke, strokeWidth } = this.props;

    ctx.beginPath();
    ctx.strokeStyle = stroke;
    ctx.lineWidth = strokeWidth;
    ctx.moveTo(point.x1, point.y1);
    ctx.lineTo(point.x2, point.y2);
    ctx.stroke();
    return;
  }
  renderSVG(moreProps) {
    const point = helper(this.props, moreProps);
    if (isNotDefined(point)) return;
    const { stroke, strokeWidth } = this.props;

    return (
      <line
        x1={point.x1}
        y1={point.y1}
        x2={point.x2}
        y2={point.y2}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    );
  }
  render() {
    return (
      <GenericComponent
        svgDraw={this.renderSVG}
        canvasDraw={this.drawOnCanvas}
        drawOn={["mousemove"]}
        {...this.props}
      />
    );
  }
}

StaticLine.propTypes = {
  align: PropTypes.string,
  percentage: PropTypes.number,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number
};

StaticLine.defaultProps = {
  align: "bottom",
  percentage: 25,
  stroke: "#FFFFFF",
  strokeWidth: 0.4
};

function helper(props, moreProps) {
  const { align, percentage } = props;
  const { width, height } = moreProps;
  if (isNotDefined(percentage)) return null;
  if (align.toLowerCase() === "top") {
    return {
      x1: width / 2,
      y1: 0,
      x2: width / 2,
      y2: (height * percentage) / 100
    };
  } else if (align.toLowerCase() === "left") {
    return {
      x1: 0,
      y1: height / 2,
      x2: (width * percentage) / 100,
      y2: height / 2
    };
  } else if (align.toLowerCase() === "right") {
    return {
      x1: width - (width * percentage) / 100,
      y1: height / 2,
      x2: width,
      y2: height / 2
    };
  } else {
    return {
      x1: width / 2,
      y1: height - (height * percentage) / 100,
      x2: width / 2,
      y2: height
    };
  }
}

export default StaticLine;