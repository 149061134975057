import React from "react";
import { Helmet } from 'react-helmet'
import Grid from '@material-ui/core/Grid';
import { ImCircleLeft} from "react-icons/im";
import defaultdata from "../general/defaultdata";
import MarketDataChart from "./marketdatachart";
import MarketDepthData from "./marketdepthdata";
import ForecastabilityItem from "./forecastability_item"
import HeadingWithInfo from "../general/headingwithinfo";
import DataTableSml from "./datatable_sml"
import EdgeRowWidgetsWide from "./edgerowwidgetswide";
import ForecastDisplay from "./forecastdisplayitem"
import ItemDetails from "./itemdetails";
import Spinner from 'react-spinner-material';
import EdgeForecastChart from "./edgechart";
import "react-pro-sidebar/dist/css/styles.css";
import "../../assets/css/main.css"
import { tickStep } from "d3";

class SymbolDetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            edgeitems: null,
            loading: true,
            dataloaded: false,
            counter: 0,
            dataendpoint: "http://127.0.0.1:5000/",
            
            // generaldata: defaultdata.defaultchartdata.generaldata,
            // mainchartseries: defaultdata.defaultchartdata.mainchartseries,
            // accuracychartseries: defaultdata.defaultchartdata.accuracychartseries,
            // pricedistributionseries: defaultdata.defaultchartdata.pricedistributionseries,
            // strengthchartseries: defaultdata.defaultchartdata.strengthchartseries,
            // tradespeedseries: defaultdata.defaultchartdata.tradespeedseries,
            // mainchartoptions: defaultdata.defaultchartoptions.mainchartoptions,
            // accuracychartoptions: defaultdata.defaultchartoptions.accuracychartoptions,
            // pricedistributionoptions: defaultdata.defaultchartoptions.pricedistributionoptions,
            // strengthchartoptions: defaultdata.defaultchartoptions.strengthchartoptions,
            // tradespeedoptions: defaultdata.defaultchartoptions.tradespeedoptions,
            // descriptions: defaultdata.defaultdescriptions,
            // forecastdata: defaultdata.defaultdata.forecastdata,
            settingdata: this.props.settingdata,      
            descriptions: defaultdata.defaultdescriptions,
            depthdata: defaultdata.defaultdata.depthdata,
            chartdata: defaultdata.defaultdata.chartdata,
            displaychartdata: defaultdata.defaultdata.displaychartdata,
            generaldata: defaultdata.defaultdata.generaldata,
            forecastdata: defaultdata.defaultdata.forecastdata,
            widgetdata: defaultdata.defaultdata.widgetdata,

            activechartitem: {
                lastprice: false,
                change24Hr: false,
                high24Hr: false,
                low24Hr: false,
                totaltrades: false,
                beta: false,
                value24Hr: false,
                volume24Hr: false,
                volumepermin: false,
                changepermin: false,
                tradespermin: false,
                sharpe: false,
                rsi: true,
                macd: false,
                mfi: false,
                stoch: false,
                volatility: false,
                sortino: false
            },
            activechartname: 'rsi',
            activechartoptions: defaultdata.defaultchartoptions.indicatorchartoptions['rsi']
        }
        this.closeSymbolDetail = this.closeSymbolDetail.bind(this)
        this.selectedactivechartitem = this.selectedactivechartitem.bind(this)
    }
    closeSymbolDetail(){
        this.props.switchComponent("Edge");
    }
    selectedactivechartitem(itemname) {        
        var oldstate = this.state;
        var newstate = {
            ...oldstate,
            activechartitem: {
                lastprice: false,
                change24Hr: false,
                high24Hr: false,
                low24Hr: false,
                totaltrades: false,
                beta: false,
                value24Hr: false,
                volume24Hr: false,
                volumepermin: false,
                changepermin: false,
                tradespermin: false,
                sharpe: false,
                rsi: false,
                macd: false,
                mfi: false,
                stoch: false,
                volatility: false,
                sortino: false
            }
        }
        newstate['activechartitem'][itemname] = true;
        newstate['activechartname'] = itemname;
        newstate['activechartoptions'] = defaultdata.defaultchartoptions.indicatorchartoptions[itemname]
        this.setState(newstate);
        console.log(newstate)
    }
    updatedata(){
        let headers = new Headers();
        headers.append('Content-Type', 'text/html');    
        headers.append('Access-Control-Allow-Origin','*');
        headers.append('Origin','*');  
        // headers.append('Origin','http://localhost:3000');
    
        if(!this.state.loading){
          if(!this.state.dataloaded) {
            fetch('https://'+this.props.settingdata.dataendpoint+'/getsymbolfulldata', { 
              method: 'get', headers: headers, mode: 'cors'
            }).then(response => response.json()
            ).then((data) => {
                

              var oldState = this.state;
              var newState = {
                ...oldState,
                depthdata: data.depthdata,
                chartdata: data.chartdata,
                displaychartdata: data.displaychartdata,
                generaldata: data.generaldata,
                forecastdata: data.forecastdata,
                widgetdata: data.widgetdata,
                counter: 50,
                dataloaded: true
    
              }
              this.setState(newState)
            }).catch((error) => {
                console.log("Fetch User Error");
                console.log(error)
            });
          }
          else {
            if(this.props.status === "SymbolDetail"){
              if(this.state.counter >=80){
                fetch('https://'+this.props.settingdata.dataendpoint+'/getsymbolfulldata', { 
                  method: 'get', headers: headers, mode: 'cors'
                }).then(response => response.json()
                ).then((data) => {
                    // console.log(data)  
                  var oldState = this.state;
                  var newState = {
                    ...oldState,
                    depthdata: data.depthdata,
                    chartdata: data.chartdata,
                    displaychartdata: data.displaychartdata,
                    generaldata: data.generaldata,
                    forecastdata: data.forecastdata,
                    widgetdata: data.widgetdata,
                    counter: 0
    
                  }
                  this.setState(newState)
                  // console.log(data)
                }).catch((error) => {
                    console.log("Fetch User Error");
                    console.log(error)
                });
              } else {
                fetch('https://'+this.props.settingdata.dataendpoint+'/getsymbollivedata', { 
                  method: 'get', headers: headers, mode: 'cors'
                }).then(response => response.json()
                ).then((data) => {   
                    // console.log(data)            
                    var newcounter = this.state.counter+1;
                    var oldState = this.state;
                    var newState = {
                        ...oldState,
                        depthdata: data.depthdata,
                        generaldata: data.generaldata,
                        counter: newcounter
    
                  }
                  this.setState(newState)
                }).catch((error) => {
                    console.log("Fetch User Error");
                    console.log(error)
                });
              }
            }
          }
        //   console.log(this.state.counter)
        }
        // console.log(this.state)
    }
    componentDidMount() {
    if(this.props.settingdata) {
        
        this.setState({loading: false, settingdata:this.props.settingdata});
        setInterval(()=>{ this.updatedata()}, 500);
    }
    }

    render() {        
        const siteTitle = 'Edge Detail'
        const { edgeitems } = this.state;
        const isLoading = edgeitems === null;
        // console.log(this.state)
        
        return (
            <React.Fragment>
                <Helmet>
                <title>EdgeForecaster - {siteTitle}</title>
                <meta name="description" content="EdgeForecaster" />
                </Helmet>
                <div id="main" className="wrapper style1">
                    <div className="container">          
                        <div className="row gtr-150">            
                            <div className="col-12 col-12-medium imp-medium">
                                <section id="content">
                                <Grid container spacing={1}>
                                    <Grid item xs={8}>
                                        <h1>EdgeSymbol Detail</h1>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} >
                                        <div className="table-container">
                                            <div className="uk-overflow-auto">
                                                <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
                                                    <tbody>
                                                        {this.props.loading
                                                        ?   
                                                            <tr>
                                                                <td colSpan={6} className="uk-text-center">                                          
                                                                    <div className="loadingspinneroverlay">
                                                                        <Spinner radius={120} color={"#4648ff"} stroke={2} visible={true} />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        : 
                                                        <   tr key={this.props.itemindx} >
                                                                <td>
                                                                    <Grid container spacing={1}>
                                                                        <Grid item xs={12} >
                                                                            <Grid container spacing={1}>
                                                                                <Grid item xs={10} style={{backgroundColor: "#272833"}}>
                                                                                    <Grid container spacing={1}>
                                                                                        <Grid item xs={3}>
                                                                                            <Grid container spacing={0} className="edgeforecast_navarrowcontainer">
                                                                                                <Grid item xs={1}>
                                                                                                    <div className="edgeforecast_nav_arrowback" onClick={this.closeSymbolDetail}  ><ImCircleLeft /></div>
                                                                                                </Grid>
                                                                                                <Grid item xs={11} />
                                                                                            </Grid>
                                                                                            <Grid container spacing={0}>
                                                                                                <Grid item xs={12}>
                                                                                                    <ItemDetails edgename={this.props.settingdata.edgename} 
                                                                                                        exchange={this.props.settingdata.exchange}
                                                                                                        lastprice={this.state.generaldata[1]}
                                                                                                        change24Hr={this.state.generaldata[2]}/>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                            <Grid container spacing={0}>
                                                                                                <Grid item xs={12}>
                                                                                                    <ForecastabilityItem forecastability={this.state.forecastdata.forecastability} 
                                                                                                    accuracychartseries={this.state.forecastdata.accuracy  } accuracychartoptions={defaultdata.defaultchartoptions.accuracychartoptions} />           
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <Grid item xs={9}>
                                                                                            <div className="edgeforecast_datacontainer" >
                                                                                                <DataTableSml griditemsize={2} dataitems={[this.state.generaldata.slice(1,7),
                                                                                                    this.state.generaldata.slice(7,13), this.state.generaldata.slice(13,19)]} 
                                                                                                    activechartitemslist={this.state.activechartitem}
                                                                                                    descriptions={defaultdata.defaultdescriptions} selectedactivechartitem={this.selectedactivechartitem} 
                                                                                                    tableheading = {"Overview"} tabledesc={"To be done"}/>
                                                                                            
                                                                                            </div>
                                                                                            
                                                                                        </Grid>                                                                                
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item xs={2} style={{backgroundColor: "#272833"}}>
                                                                                    <ForecastDisplay forecastitems={this.state.forecastdata.forecasts} vertical={false} />
                                                                                </Grid>                
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs={12} >
                                                                            <Grid container spacing={1} >
                                                                                <Grid item xs={6} style={{backgroundColor: "#272833"}}>
                                                                                    <MarketDataChart data={this.state.depthdata.chartdata} />
                                                                                </Grid>
                                                                                <Grid item xs={6} style={{backgroundColor: "#272833"}}>
                                                                                    <EdgeForecastChart charttype={this.state.activechartoptions.charttype} heading={"Indicator Chart"} showheading={true} data={this.state.displaychartdata} chartname={this.state.activechartname}
                                                                                        displayitem={this.state.activechartname} height={400} showmavs={true} chartoptions={this.state.activechartoptions} /> 
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs={12} >
                                                                            <Grid container spacing={1} >
                                                                                <Grid item xs={6} style={{backgroundColor: "#272833"}}>
                                                                                    <Grid container spacing={0}>
                                                                                        <Grid item xs={12}>
                                                                                            <HeadingWithInfo heading={"Market Depth"} headingclassname="edgeforecast_catheading" description={this.props.description} />
                                                                                        </Grid>
                                                                                        <Grid item xs={6}>
                                                                                            <MarketDepthData heading="Bids (best)" depthdata={this.state.depthdata.bidsfull} description={defaultdata.defaultdescriptions.marketdepth_bestbids}/>
                                                                                        </Grid>
                                                                                        <Grid item xs={6}>
                                                                                            <MarketDepthData heading="Asks (best)" depthdata={this.state.depthdata.asksfull} description={defaultdata.defaultdescriptions.marketdepth_bestasks}/>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    
                                                                                </Grid>
                                                                                <Grid item xs={6} style={{backgroundColor: "#272833"}}>
                                                                                    <EdgeRowWidgetsWide widgetdata={this.state.widgetdata} generaldata={this.state.generaldata} 
                                                                                    descriptions={defaultdata.defaultdescriptions} chartoptions={defaultdata.defaultchartoptions} />
                                                                                    
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs={12} style={{backgroundColor: "#272833"}}>
                                                                            
                                                                        </Grid>
                                                                    </Grid>
                                                                </td>
                                                            </tr>                                        
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>              
                            </section>
                        </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );    
    }
}

export default SymbolDetailPage