import React from "react";
import { Checkbox, Grid } from '@material-ui/core';
import { ImMinus } from "react-icons/im";
import Spinner from 'react-spinner-material';
import "react-pro-sidebar/dist/css/styles.css";
import "../../assets/css/main.css"

class SearchListItem extends React.Component {  
  constructor(props) {
    super(props);
    this.state = { 
      usersettings: null,
      loading: true,
      symboldata: {
        lastprice: { value: '0.00', color: '#dddddd' },
        change24hr: { value: '0.00%', color: '#dddddd' },
        tradespermin: { value: '0', color: '#dddddd' },
        forecastability: { value: '0', color: '#dddddd' },
        shortterm: { value: '0.00%', color: '#dddddd', image: <ImMinus/>, classname: 'settings_searchitem_pred_same'},
        longterm: { value: '0.00%', color: '#dddddd', image: <ImMinus/>, classname: 'settings_searchitem_pred_same' },
        selected: false
      },     
    }
    this.selectSymbol = this.selectSymbol.bind(this)
  }
  
  componentDidMount() {
    setTimeout(()=>{
      this.setState({loading:false})
    }, 2500)
  }

  selectSymbol(){
    var oldState = this.state;
    var newstate = {
      ...oldState
    }
    if(!this.state.symboldata.selected){ 
      newstate = {
        ...newstate,
        symboldata: {
          ...oldState.symboldata,
          selected: true
        }
      }     
      this.setState(newstate)
    } else { 
      newstate = {
        ...newstate,
        symboldata: {
          ...oldState.symboldata,
          selected: false
        }
      }     
      this.setState(newstate)
    }
  }
  render() {
    return (
      <div>
        <Grid container spacing={0}>
          <Grid item xs={2} className="settings_searchtable_data" style={{textAlign:"start"}} >{this.props.searchitem.edgename}</Grid>
          <Grid item xs={1} className="settings_searchtable_data" >
            {/* <Spinner size={2} spinnerColor={"#252525"} spinnerWidth={2} visible={this.state.loading} /> */}
            <Spinner radius={2} color={"#252525"} stroke={2} visible={this.state.loading} />
          </Grid>
          <Grid item xs={1} className="settings_searchtable_data" 
            style={{color:this.state.symboldata.lastprice.color}}>
              {this.state.symboldata.lastprice.value}
          </Grid>
          <Grid item xs={1} className="settings_searchtable_data" 
            style={{color:this.state.symboldata.change24hr.color}}>
              {this.state.symboldata.change24hr.value}
          </Grid>
          <Grid item xs={1} className="settings_searchtable_data" 
            style={{color:this.state.symboldata.tradespermin.color}}>
              {this.state.symboldata.tradespermin.value}
          </Grid>
          <Grid item xs={1} className="settings_searchtable_data" > </Grid>
          <Grid item xs={2} className="settings_searchtable_data" 
            style={{color:this.state.symboldata.forecastability.color}}>
              {this.state.symboldata.forecastability.value}
          </Grid>
          <Grid item xs={1} className="settings_searchtable_data" 
            style={{color:this.state.symboldata.shortterm.color}}>
              <div className={this.state.symboldata.shortterm.classname}>
                {this.state.symboldata.shortterm.value}
                {this.state.symboldata.shortterm.image}
              </div>              
          </Grid>
          <Grid item xs={1} className="settings_searchtable_data" 
            style={{color:this.state.symboldata.longterm.color}}>
              <div className={this.state.symboldata.longterm.classname}>
                {this.state.symboldata.longterm.value}
                {this.state.symboldata.longterm.image}
              </div>
          </Grid>
          <Grid item xs={1} className="settings_searchtable_data" >
            <Checkbox checked={this.state.symboldata.selected } style={{color:"#dddddd"}} 
                className="settings_searchitem_checkbox" onClick={this.selectSymbol}/>
          </Grid>
        </Grid>        
      </div>
    )};
}

export default SearchListItem