import React from "react";
import Authentication from "./components/auth/auth_handler";

class AppWithAuth extends React.Component {  
  render() {
    
    return (
      <div>
        <Authentication />        
      </div>
    );
    
  }
}

export default AppWithAuth;
