import React from "react";

function NumberFormatter(number) {

    if(number < -1) {
        return number.toFixed(2)
    }
    if(number < 1) {
        return number.toFixed(4)
    }

    if(number >= 100000){
        if (number >= 1000000000) {
            return (number / 1000000000).toFixed(2).replace(/\.0$/, '') + 'B';
        }
        if (number >= 1000000) {
            return (number / 1000000).toFixed(2).replace(/\.0$/, '') + 'M';
        }
        return (number / 1000).toFixed(2).replace(/\.0$/, '') + 'K';        
    }
    if(number >= 1000){
        var parts=number.toString().split(".");
        return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // + (parts[1] ? "." + parts[1] : "");
    }

    if(number >= 1) {
        return number.toFixed(2)
    }    
}

export default NumberFormatter