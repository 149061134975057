import React from "react";
import Grid from '@material-ui/core/Grid';
import HeadingWithInfo from "../general/headingwithinfo";
import NumberFormatter from "../../utils/numberformater";
import "../../assets/css/main.css"

class DashboardDataItem extends React.Component {    
    constructor(props) {
        super(props);
        this.handleitemclick = this.handleitemclick.bind(this);        
    }

    handleitemclick() {        
        this.props.selectedactivechartitem(this.props.itemid);
    }
    
    render() {        
        var heading = this.props.descriptions[this.props.itemid].heading;        
        var desc = this.props.descriptions[this.props.itemid].description;
        
        
        if(this.props.activechartitemslist[this.props.itemid]) {
            return (
                <React.Fragment>                    
                    <Grid container spacing={0} style={{border:"#4648ff", borderWidth: "0.15vmin", borderStyle:"solid", borderRadius:"0.5vmin"}}> 
                        <Grid item xs={12}>
                            <HeadingWithInfo heading={heading} headingclassname="edgeforecast_catheading" description={desc} />
                        </Grid>                                    
                        <Grid item xs={12} onClick={this.handleitemclick} style={{cursor:"pointer"}} >                            
                            <div className="edgeforecast_data" style={{color:this.props.colour}}>{this.props.value}</div>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )

        } else {
            return (
                <React.Fragment>             
                    <Grid container spacing={0}> 
                        <Grid item xs={12}>
                            <HeadingWithInfo heading={heading} headingclassname="edgeforecast_catheading" description={desc} />
                        </Grid>                                    
                        <Grid item xs={12} onClick={this.handleitemclick} style={{cursor:"pointer", caretColor:"#4648ff"}}>
                            <div className="edgeforecast_data" style={{color:this.props.colour}}>{NumberFormatter(this.props.value)}</div>
                        </Grid>
                    </Grid>             
                </React.Fragment>
            );
        }
    }
}

export default DashboardDataItem