import React from "react";
import { Helmet } from 'react-helmet'
import SymbolEdgeRow from "./symboledgerow";
import { Grid } from '@material-ui/core';
import Spinner from 'react-spinner-material';
import "react-pro-sidebar/dist/css/styles.css";
import "../../assets/css/main.css"

class EdgeForecast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {       
      dataloaded: false        
    }
  }  
  componentDidMount() {
    this.props.toggleLoading();
  }
  render() {        
    const siteTitle = 'Edge List'
    console.log(this.props)
    return (
        <React.Fragment>
            <Helmet>
              <title>EdgeForecaster - {siteTitle}</title>
              <meta name="description" content="EdgeForecaster" />
            </Helmet>
            <div id="main" className="wrapper style1">
              <div className="container">          
                <div className="row gtr-150">            
                  <div className="col-12 col-12-medium imp-medium">
                    <section id="content">
                      <Grid container spacing={1}>
                        <Grid item xs={8}>
                          <h1>EdgeList</h1>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid item xs={12} >
                          <div className="table-container">
                              <div className="uk-overflow-auto">
                                <table className="basetable uk-table uk-table-hover uk-table-middle uk-table-divider">
                                    <tbody>                                
                                      {this.props.loading
                                        ? <tr>
                                            <td colSpan={6} className="uk-text-center">                                          
                                              <div className="loadingspinneroverlay">
                                                <Spinner radius={120} color={"#4648ff"} stroke={2} visible={true} />
                                              </div>
                                            </td>
                                          </tr>
                                        : 
                                        this.props.userdetails.settings.map((settingdata, index) => {
                                          // console.log(settingdata)
                                            return (
                                              
                                              <SymbolEdgeRow key={index} index={index} settingdata={settingdata} setdata={this.props.setdata} switchComponent={this.props.switchComponent} status={this.props.status} />
                                              )
                                          })
                                        }
                                        
                                    </tbody>
                                </table>
                              </div>
                          </div>
                        </Grid>
                      </Grid>
                    
                    </section>
                  </div>
                </div>
              </div>
            </div>
        </React.Fragment>
    );    
  }
}

export default EdgeForecast