import React from "react";
import { Helmet } from 'react-helmet'
import Grid from '@material-ui/core/Grid';
import Spinner from 'react-spinner-material';
import logo from '../../assets/images/logo.png';
import "react-pro-sidebar/dist/css/styles.css";
import "../../assets/css/main.css"

class ServerConnectionError extends React.Component {
  constructor(props) {
    super(props);
    this.state = {       
      dataloaded: false        
    }
  }  
  componentDidMount() {
    
  }
  handleRetryConnection = event => {
    this.props.switchComponent("Welcome")
  }
  render() {        
    const siteTitle = 'Edge List'
    // console.log(this.props)
    return (
        <React.Fragment>
            <Helmet>
              <title>EdgeForecaster - {siteTitle}</title>
              <meta name="description" content="EdgeForecaster" />
            </Helmet>
            <div id="main" className="wrapper style1">
              <div className="container">          
                <div className="row gtr-150">            
                  <div className="col-12 col-12-medium imp-medium">
                    <section id="content">
                      <div className="table-container">
                          <div className="uk-overflow-auto">
                            <Grid container spacing={0} >
                              <Grid item xs={6} />
                              <Grid item xs={1}>
                                <div className="loadingspinneroverlay">
                                  <Spinner radius={120} color={"#4648ff"} stroke={2} visible={true} />
                                </div>
                              </Grid>
                              <Grid item xs={6} />
                            </Grid>
                            <Grid container spacing={0}>
                              <Grid item xs={4} />
                              <Grid item xs={4}>
                                <img src={logo} alt="Logo" className="login_page_logo"/>
                              </Grid>
                              <Grid item xs={4} />
                            </Grid>
                            <Grid container spacing={1}>
                              <Grid item xs={4} />
                              <Grid item xs={4}>
                                <div style={{color:"#ffffff"}}>Unfortunately we cannot connect to the EdgeForecaster Cloud at the moment. Please check your connectivity and try again.</div>
                                <Grid container spacing={2} style={{marginTop:"2.5vmin"}}>
                                  <Grid item xs={3} />
                                  <Grid item xs={4}>
                                    <button onClick={this.handleRetryConnection} className="login_page_button">Retry Cloud Connect</button>
                                  </Grid>
                                  <Grid item xs={3} />
                                </Grid>
                              </Grid>
                              <Grid item xs={4} />
                            </Grid>
                          </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
        </React.Fragment>
    );    
  }
}

export default ServerConnectionError