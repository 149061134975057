import React, { Component} from 'react';
import Sidebar from './sidebar'
import EdgeForecast from '../edgeforecaster/edgeforecast';
import ProfilePage from './profilepage';
import SymbolSearch from './symbolsearch';
import HelpPage from './helppage';
import LegalsPage from './legalspage';
import SymbolDetailPage from '../edgeforecaster/symboldetailpage';
import ServerConnectionError from './servererror';
import Spinner from 'react-spinner-material';
import defaultdata from "../general/defaultdata";
import '../../assets/css/App.css'
import '../../assets/css/main.css'

class EdgeForecastApp extends Component {
    constructor(props, context) {
        super(props);
        this.state = {
          loading: true,
          dataloaded: false,
          initalload: true,
          counter: 0,
          status: "Edge",
        //   status: "SymbolDetail",
          mainapi: "http://localhost:5050/",
        };
        this.switchComponent = this.switchComponent.bind(this);
        this.pageComponent = this.pageComponent.bind(this);
        this.setData = this.setData.bind(this);
        this.setItemIndx = this.setItemIndx.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
    }
    switchComponent(status){
        this.setState({ 
            status: status,
            loading: true,
            dataloaded: true
         });

    };
    setData(data){
        this.setState({ data });
    };
    setItemIndx(itemindx){
        this.setState({ itemindx });
    };
    toggleLoading(){
        var oldLoading = this.state.loading;
        this.setState({loading: !oldLoading, dataloaded: oldLoading })
    }
    pageComponent(){
        if(this.props.loading) { 
            return( 
              <React.Fragment>
                <div className="loadingspinneroverlay">
                  <Spinner radius={120} color={"#4648ff"} stroke={2} visible={true} />
                </div>
              </React.Fragment>
            )
        } else {
            switch (this.state.status) {
                case "Edge":
                    return (
                        <EdgeForecast loading={!this.state.dataloaded } authstate={this.props.authstate} userdetails={this.props.userdetails} status={this.state.status}
                            setdata={this.setData} switchComponent={this.switchComponent} setitemindx={this.setItemIndx} toggleLoading={this.toggleLoading} />
                    );            
                case "Profile":
                    return (
                        <ProfilePage usersettings={this.props.userdetails.settings} userdetails={this.props.userdetails} />
                    );            
                case "Search":
                    return (
                        <SymbolSearch usersettings={this.props.userdetails.settings} />
                    ); 
                case "Help":
                    return (
                        <HelpPage />
                    ); 
                case "Legal":
                    return (
                        <LegalsPage />
                    );
                case "ServerError":
                    return (
                        <ServerConnectionError />
                    );
                case "SymbolDetail":
                    // this.setData(this.props.usersettings[this.state.itemindx])
                    return (
                        <SymbolDetailPage loading={!this.state.dataloaded } itemindx={1} settingdata={this.state.data.settingdata} depthdata={this.state.data.depthdata} chartdata={this.state.data.chartdata} status={this.state.status}
                            displaychartdata={this.state.data.displaychartdata} generaldata={this.state.data.generaldata} forecastdata={this.state.data.forecastdata} widgetdata={this.state.data.widgetdata} chartoptions={defaultdata.defaultchartoptions} 
                            descriptions={defaultdata.defaultdescriptions} switchComponent={this.switchComponent}  />
                    );
                default:
                    return (
                        <ServerConnectionError />
                    );
            }
        }
    };
    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    };    
    render() {
        const siteTitle = 'EdgeForecaster'        
        return (
            <React.Fragment>
                <Sidebar switchComponent={this.switchComponent} />
                <div id="page-wrapper">
                    <div className="edgeforecast_container">                        
                        {this.pageComponent()}
                    </div>                                        
                </div>                 
            </React.Fragment>
        );
    }
}
export default EdgeForecastApp