import React from "react";
import Grid from '@material-ui/core/Grid';
import { ImArrowUp, ImArrowDown } from "react-icons/im";
import HeadingWithInfo from "../general/headingwithinfo";
import NumberFormatter from "../../utils/numberformater";
import "../../assets/css/main.css"

class ForecastDisplayItem extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
        expanded: false,           
      }
    }
    render() {        
        // {this.props.value >= 0 ? "edgeforecast_pred_up" : "edgeforecast_pred_dwn"}     
        return (
            <Grid item xs={this.props.displaywidth} className="edgeforecast_pred" id={"Forecastitem_"+this.props.index}>                 
                <HeadingWithInfo heading={this.props.forecastname} headingclassname="edgeforecast_forecast_heading" description={this.props.description} />
                <div style={this.props.value >=0?{color:"#00B746"}:{color:"#EF403C"}} className="edgeforecast_forecast_arrow">{this.props.value >= 0 ? <ImArrowUp/> : <ImArrowDown/>}</div>
                <div style={this.props.value >=0?{color:"#00B746"}:{color:"#EF403C"}} className="edgeforecast_forecast_heading">{NumberFormatter(this.props.value)} %</div>
            </Grid>
        );
    }
}

class ForecastDisplay extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
        expanded: false,           
      }
    }

    


    render() {        
        var forecastitems = [
            {
                "forecastname":"Short Term",
                "value":this.props.forecastitems.shortterm, 
                "description": "Short Term Forecast"
            },
            {
                "forecastname":"Long Term",
                "value":this.props.forecastitems.longterm, 
                "description": "Long Term Forecast"
            }]
        return (
            <React.Fragment>
                {this.props.vertical ? 
                <Grid container spacing={0} >
                    {forecastitems.map((forecastItem, index) => {
                        return (
                            <Grid container spacing={0} >
                                <ForecastDisplayItem key={index} index={index} forecastname={forecastItem.forecastname} value={forecastItem.value} 
                                    description={forecastItem.description} displaywidth={12} />
                            </Grid>
                        )
                    })}
                </Grid>
                :
                <Grid container spacing={0} >
                    {forecastitems.map((forecastItem, index) => {
                        return <ForecastDisplayItem key={index+1} forecastname={forecastItem.forecastname} value={forecastItem.value} 
                                    description={forecastItem.description} displaywidth={6} />
                    })}
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default ForecastDisplay