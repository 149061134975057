import React from "react";
import Grid from '@material-ui/core/Grid';
import DashboardWidget from "./dashboardwidget";
import HeadingWithInfo from "../general/headingwithinfo";
import NumberFormatter from "../../utils/numberformater";
import "../../assets/css/main.css"

class IndicatorSnapShotStacked extends React.Component {    
    render() {        
        var alphadetails = this.props.generaldata[16]
        alphadetails['heading'] = this.props.descriptions[alphadetails.itemid].heading
        alphadetails['description'] = this.props.descriptions[alphadetails.itemid].description
        // alphadetails['value'] = alphadetails.value*1.31

        var betadetails = this.props.generaldata[16]
        betadetails['heading'] = this.props.descriptions[betadetails.itemid].heading
        betadetails['description'] = this.props.descriptions[betadetails.itemid].description
        // betadetails['value'] = betadetails.value*0.21

        var sharpedetails = this.props.generaldata[15]
        sharpedetails['heading'] = this.props.descriptions[sharpedetails.itemid].heading
        sharpedetails['description'] = this.props.descriptions[sharpedetails.itemid].description

        var sortinodetails = this.props.generaldata[16]
        sortinodetails['heading'] = this.props.descriptions[sortinodetails.itemid].heading
        sortinodetails['description'] = this.props.descriptions[sortinodetails.itemid].description

        var rsidetails = this.props.generaldata[11]
        rsidetails['heading'] = this.props.descriptions[rsidetails.itemid].heading
        rsidetails['description'] = this.props.descriptions[rsidetails.itemid].description

        var mfidetails = this.props.generaldata[13]
        mfidetails['heading'] = this.props.descriptions[mfidetails.itemid].heading
        mfidetails['description'] = this.props.descriptions[mfidetails.itemid].description

        var stochdetails = this.props.generaldata[14]
        stochdetails['heading'] = this.props.descriptions[stochdetails.itemid].heading
        stochdetails['description'] = this.props.descriptions[stochdetails.itemid].description

        var volatilitydetails = this.props.generaldata[17]
        volatilitydetails['heading'] = this.props.descriptions[volatilitydetails.itemid].heading
        volatilitydetails['description'] = this.props.descriptions[volatilitydetails.itemid].description

        return (
            <React.Fragment>
                <Grid container spacing={0}>                    
                    <Grid item xs={12}>
                            <HeadingWithInfo heading="Indicator Snapshots" headingclassname="edgeforecast_catheading" description={this.props.description} />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <DashboardWidget index={1} widget="heatsnapshot" heading="Alpha " value={NumberFormatter(alphadetails.value*0.32)}
                                            thresholds = {{lvl1:0, lvl2:0.3, lvl3:0.6, lvl4:1, lvl5:1.3, lvl6:1.6, lvl7:1.9, lvl8:2.3, lvl9:2.6, lvl10:3}}
                                            description={alphadetails.description} options={this.props.options.strengthchartoptions} />                              
                                    </Grid> 
                                    <Grid item xs={12}>
                                        <DashboardWidget index={1} widget="heatsnapshot" heading="Beta " value={NumberFormatter(betadetails.value)}
                                            thresholds = {{lvl1:0, lvl2:0.3, lvl3:0.6, lvl4:1, lvl5:1.3, lvl6:1.6, lvl7:1.9, lvl8:2.3, lvl9:2.6, lvl10:3}}
                                            description={betadetails.description} options={this.props.options.strengthchartoptions} />                              
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DashboardWidget index={1} widget="heatsnapshot" heading="Sharpe " value={NumberFormatter(sharpedetails.value)}
                                            thresholds = {{lvl1:0, lvl2:0.3, lvl3:0.6, lvl4:1, lvl5:1.3, lvl6:1.6, lvl7:1.9, lvl8:2.3, lvl9:2.6, lvl10:3}}
                                            description={sharpedetails.description} options={this.props.options.strengthchartoptions} />                           
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DashboardWidget index={1} widget="heatsnapshot" heading="Sortino " value={NumberFormatter(sortinodetails.value)}
                                            thresholds = {{lvl1:0, lvl2:0.3, lvl3:0.6, lvl4:1, lvl5:1.3, lvl6:1.6, lvl7:1.9, lvl8:2.3, lvl9:2.6, lvl10:3}}
                                            description={sortinodetails.description} options={this.props.options.strengthchartoptions} /> 
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <DashboardWidget index={1} widget="heatsnapshot" heading="RSI " value={NumberFormatter(rsidetails.value)}
                                            thresholds = {{lvl1:0, lvl2:10, lvl3:20, lvl4:30, lvl5:40, lvl6:50, lvl7:60, lvl8:70, lvl9:80, lvl10:90}}
                                            description={rsidetails.description} options={this.props.options.strengthchartoptions} /> 
                                    </Grid> 
                                    <Grid item xs={12}>
                                        <DashboardWidget index={1} widget="heatsnapshot" heading="MFI " value={NumberFormatter(mfidetails.value)}
                                            thresholds = {{lvl1:0, lvl2:10, lvl3:20, lvl4:30, lvl5:40, lvl6:50, lvl7:60, lvl8:70, lvl9:80, lvl10:90}}
                                            description={mfidetails.description} options={this.props.options.strengthchartoptions} /> 
                                    </Grid> 
                                    <Grid item xs={12}>
                                        <DashboardWidget index={1} widget="heatsnapshot" heading="Stoch " value={NumberFormatter(stochdetails.value)}
                                            thresholds = {{lvl1:0, lvl2:10, lvl3:20, lvl4:30, lvl5:40, lvl6:50, lvl7:60, lvl8:70, lvl9:80, lvl10:90}}
                                            description={stochdetails.description} options={this.props.options.strengthchartoptions} /> 
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DashboardWidget index={1} widget="heatsnapshot" heading="Volatility " value={NumberFormatter(volatilitydetails.value)}
                                            thresholds = {{lvl1:0, lvl2:10, lvl3:20, lvl4:30, lvl5:40, lvl6:50, lvl7:60, lvl8:70, lvl9:80, lvl10:90}}
                                            description={volatilitydetails.description} options={this.props.options.strengthchartoptions} /> 
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>                                    
                </Grid>                
            </React.Fragment>
        );
    }
}

export default IndicatorSnapShotStacked