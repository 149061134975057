import React, { Component }  from "react";
import {ProSidebar, Menu, MenuItem, SidebarHeader, SidebarFooter, SidebarContent} from "react-pro-sidebar";
import { ImCircleLeft, ImCircleRight} from "react-icons/im";
import { FiHome, FiLogOut, FiList} from "react-icons/fi";
import { IoHelpSharp } from "react-icons/io5";
import { BiSearch, BiUser} from "react-icons/bi";
import "react-pro-sidebar/dist/css/styles.css";
import "../../assets/css/sidebar.css";


class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state= {
      menuCollapse: true
    }
    this.menuselection = this.menuselection.bind(this)     
  }    
  menuIconClick = () => {    
    if(this.state.menuCollapse){
      this.setState({menuCollapse:false})
    } else {
      this.setState({menuCollapse:true})
    }        
  };
  menuselection(selectionItem) {        
    this.props.switchComponent(selectionItem);
    if(!this.state.menuCollapse){
      this.setState({menuCollapse:true})
    }
  }
  render() {
    return (
      <div id="sidebar">
        <ProSidebar collapsed={this.state.menuCollapse} rtl={true} toggled='ar'>
          <SidebarHeader>          
            <div className="closemenu" onClick={this.menuIconClick}>
              {this.state.menuCollapse ? (
                <ImCircleLeft/>
              ) : (
                <ImCircleRight/>
              )}
            </div>
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="square">
              <div onClick={() => this.menuselection("Edge")}>
                <MenuItem icon={<FiHome />} >EdgeForecast</MenuItem>
              </div>
              <div onClick={() => this.menuselection("Profile")}>
                <MenuItem icon={<BiUser />}>Profile</MenuItem>
              </div>
              <div onClick={() => this.menuselection("Search")}>
                <MenuItem icon={<BiSearch />}>Search</MenuItem>
              </div>
            </Menu>
          </SidebarContent>
          <SidebarFooter>
            <Menu iconShape="square">
              <div onClick={() => this.menuselection("Help")}>
                <MenuItem icon={<IoHelpSharp />}>Help</MenuItem>
              </div>
              <div onClick={() => this.menuselection("Legal")}>
                <MenuItem icon={<FiList />}>Legal</MenuItem>
              </div>
              <div onClick={() => this.menuselection("Logout")}>
                <MenuItem icon={<FiLogOut />}>Logout</MenuItem>
              </div>
            </Menu>
          </SidebarFooter>
        </ProSidebar>
      </div>
    )};
};

export default Sidebar;