import React from "react";
import Grid from '@material-ui/core/Grid';
import DashboardWidget from "./dashboardwidget";
import IndicatorSnapShotStacked from "./indicatorsnapshotstacked";
import "../../assets/css/main.css"

class EdgeRowWidgetsWide extends React.Component {    
    render() {
        // console.log(this.props)
        return (
            <React.Fragment>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <IndicatorSnapShotStacked 
                            generaldata={this.props.generaldata}
                            descriptions={this.props.descriptions} 
                            options={this.props.chartoptions}  />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={6}>
                                        <DashboardWidget index={1} 
                                                widget="radial" 
                                                heading="Buying Strength "
                                                description={this.props.descriptions['buyingStrengthDesc']}
                                                options={this.props.chartoptions.radialwidgetoptions} 
                                                value={this.props.widgetdata.buyingpower} />                              
                                    </Grid>
                                    <Grid item xs={6}>                              
                                        <DashboardWidget index={1} 
                                                widget="bar" 
                                                heading="Trade Dist. "
                                                description={this.props.descriptions['buyingStrengthDesc']}
                                                options={this.props.chartoptions.tradedistributionoptions} 
                                                value={this.props.widgetdata.tradedistribution} />
                                        
                                    </Grid>                                
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={6}>
                                        <DashboardWidget index={1} 
                                                widget="gauge" 
                                                heading="Trade Speed "
                                                description={this.props.descriptions['buyingStrengthDesc']}
                                                options={this.props.chartoptions.tradespeedoptions} 
                                                value={this.props.widgetdata.tradespeed} />                              
                                    </Grid>
                                    <Grid item xs={6}>                              
                                        <DashboardWidget index={1} 
                                                widget="barsplit" 
                                                heading="Volume Changes "
                                                description={this.props.descriptions['buyingStrengthDesc']}
                                                options={this.props.chartoptions} 
                                                value={this.props.widgetdata.volumeChanges} />
                                        
                                    </Grid>                               
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default EdgeRowWidgetsWide