import React from 'react';
import PropTypes from "prop-types";
import Grid from '@material-ui/core/Grid';
import { ChartCanvas, Chart } from "react-stockcharts";
import { CandlestickSeries, LineSeries, BarSeries, MACDSeries } from "react-stockcharts/lib/series";
import { OHLCTooltip, MovingAverageTooltip, MACDTooltip } from "react-stockcharts/lib/tooltip";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";
import { sma, macd } from "react-stockcharts/lib/indicator";
import { fitWidth } from "react-stockcharts/lib/helper";
import { scalePoint } from  "d3-scale";
import defaultdata from '../general/defaultdata';
import HeadingWithInfo from "../general/headingwithinfo";
import PredictionSeries from '../general/PredictionSeries';

class CandleStickChartWithDarkTheme extends React.Component {
	render() {        		
		const { type, data: initialData, width, ratio } = this.props;
		const chartoptions = defaultdata.defaultchartoptions.edgechartoptions;
		const margin = { left: 70, right: 70, top: 20, bottom: 30 };		
		const gridWidth = width - margin.left - margin.right;
		const showGrid = true;
		const yGrid = showGrid ? { innerTickSize: -1 * gridWidth, tickStrokeOpacity: 0.2 } : {};		

		const smashort = sma().id(0).options({ windowSize: 1 }).merge((d, c) => {d.sma12 = c;}).accessor(d => d.sma12);
		const smalong = sma().id(2).options({ windowSize: 1 }).merge((d, c) => {d.sma72 = c;}).accessor(d => d.sma72);
		
        const calculatedData = initialData;
		const xScaleProvider = discontinuousTimeScaleProvider
			.inputDateAccessor(d => d.date);
		const {
			data,
			xScale,
			xAccessor,
			displayXAccessor,
		} = xScaleProvider(calculatedData);
	        
		return (
			<div>
				{ this.props.showmavs ? 
					<ChartCanvas height={this.props.height} width={width} ratio={ratio} margin={margin} type={type} seriesName="EdgeForecast"
						data={data} xScale={xScale} xAccessor={xAccessor} displayXAccessor={displayXAccessor} zoomEvent={false} mouseMoveEvent={false} panEvent={false} >				
						<Chart id="PriceChart" height={this.props.height} yExtents={[d => [d.bb.top, d.low]]} padding={{ top: 10, bottom: 20 }} >
							<YAxis axisAt="left" orient="left" ticks={5} {...yGrid} inverted={true} tickStroke={chartoptions.axisappearance.stokecolour} />
							<XAxis axisAt="bottom" orient="bottom" showTicks={false} outerTickSize={0} stroke={chartoptions.axisappearance.stokecolour} opacity={chartoptions.axisappearance.stokeopacity} />										
							<CandlestickSeries {...chartoptions.candlesappearance} />
							<LineSeries yAccessor={smashort.accessor()} stroke={chartoptions.mavs.shortmavcolour} fill="none" />				
							<LineSeries yAccessor={smalong.accessor()} stroke={chartoptions.mavs.longmavcolour} fill="none" />
							<PredictionSeries yAccessor={(d) => d.bb} {...chartoptions.forecastappearance} />
						</Chart>
					</ChartCanvas>
				:
					<ChartCanvas height={this.props.height} width={width} ratio={ratio} margin={margin} type={type} seriesName="EdgeForecast"
						data={data} xScale={xScale} xAccessor={xAccessor} displayXAccessor={displayXAccessor} zoomEvent={false} mouseMoveEvent={false} panEvent={false} >				
						<Chart id="PriceChart" height={this.props.height} yExtents={[d => [d.bb.top, d.low]]} padding={{ top: 10, bottom: 20 }} >
							<YAxis axisAt="left" orient="left" ticks={5} {...yGrid} inverted={true} tickStroke={chartoptions.axisappearance.stokecolour} />
							<XAxis axisAt="bottom" orient="bottom" showTicks={false} outerTickSize={0} stroke={chartoptions.axisappearance.stokecolour} opacity={chartoptions.axisappearance.stokeopacity} />										
							<CandlestickSeries {...chartoptions.candlesappearance} />							
							<PredictionSeries yAccessor={(d) => d.bb} {...chartoptions.forecastappearance} />
						</Chart>
					</ChartCanvas>
				}
			</div>
		);
	}
}
CandleStickChartWithDarkTheme.propTypes = {
	data: PropTypes.array.isRequired,
	width: PropTypes.number.isRequired,
	ratio: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["svg", "hybrid"]).isRequired,
};
CandleStickChartWithDarkTheme.defaultProps = {
	type: "svg",
};
CandleStickChartWithDarkTheme = fitWidth(CandleStickChartWithDarkTheme);

class BarChart extends React.Component {
	render() {
		const chartoptions = defaultdata.defaultchartoptions.edgechartoptions;
		const margin = { left: 70, right: 70, top: 20, bottom: 30 };
		const { data: unsortedData, type, width, ratio } = this.props;
		const dta = unsortedData;
		const xScaleProvider = discontinuousTimeScaleProvider
			.inputDateAccessor(d => d.date);
		const {
			data,
			xScale,
			xAccessor,
			displayXAccessor,
		} = xScaleProvider(unsortedData);
		console.log(dta)

		return (
			<ChartCanvas height={this.props.height} width={width} ratio={ratio} margin={margin} type={type} seriesName="EdgeForecast" data={data} xScale={xScale} 
				xAccessor={xAccessor} displayXAccessor={displayXAccessor} zoomEvent={false} mouseMoveEvent={false} panEvent={false} >
				<Chart id="PriceChart" height={this.props.height} yExtents={this.props.yaxisextent} padding={{ top: 10, bottom: 20 }} >
					<XAxis axisAt="bottom" orient="bottom" showTicks={true} outerTickSize={1} stroke={chartoptions.axisappearance.stokecolour} opacity={chartoptions.axisappearance.stokeopacity} />
					<YAxis axisAt="left" orient="left" tickValues={this.props.yaxisticks} inverted={true} tickStroke={chartoptions.axisappearance.stokecolour} />
					<BarSeries yAccessor={d => d.close} fill={"#4648ff"} />
				</Chart>
			</ChartCanvas>

		);
	}
}
BarChart.propTypes = {
	data: PropTypes.array.isRequired,
	width: PropTypes.number.isRequired,
	ratio: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["svg", "hybrid"]).isRequired,
};
BarChart.defaultProps = {
	type: "svg",
};
BarChart = fitWidth(BarChart);

class SingleLineChart extends React.Component {
	render() {
		const { type, data: initialData, width, ratio } = this.props;
		const chartoptions = defaultdata.defaultchartoptions.edgechartoptions;
		const margin = { left: 70, right: 70, top: 20, bottom: 30 };		
		const gridWidth = width - margin.left - margin.right;
		const showGrid = true;
		const yGrid = showGrid ? { innerTickSize: -1 * gridWidth, tickStrokeOpacity: 0.2 } : {};		

		const smashort = sma().id(0).options({ windowSize: 1 }).merge((d, c) => {d.close = c;}).accessor(d => d.close);
				
        const calculatedData = initialData;
		const xScaleProvider = discontinuousTimeScaleProvider
			.inputDateAccessor(d => d.date);
		const {
			data,
			xScale,
			xAccessor,
			displayXAccessor,
		} = xScaleProvider(calculatedData);
        
		return (
			<div>
 
				<ChartCanvas height={this.props.height} width={width} ratio={ratio} margin={margin} type={type} seriesName="EdgeForecast"
					data={data} xScale={xScale} xAccessor={xAccessor} displayXAccessor={displayXAccessor} zoomEvent={false} mouseMoveEvent={false} panEvent={false} >				
					<Chart id="PriceChart" height={this.props.height} yExtents={this.props.yaxisextent} padding={{ top: 10, bottom: 20 }} >
						<YAxis axisAt="left" orient="left" tickValues={this.props.yaxisticks} ticks={5} {...yGrid} inverted={true} tickStroke={chartoptions.axisappearance.stokecolour} />
						<XAxis axisAt="bottom" orient="bottom" showTicks={true} outerTickSize={1} stroke={chartoptions.axisappearance.stokecolour} opacity={chartoptions.axisappearance.stokeopacity} />
						<LineSeries yAccessor={(d)=>d.close} stroke={chartoptions.mavs.shortmavcolour} fill="none" />				
					</Chart>
				</ChartCanvas>
				
			</div>
		);
	}
}
SingleLineChart.propTypes = {
	data: PropTypes.array.isRequired,
	width: PropTypes.number.isRequired,
	ratio: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["svg", "hybrid"]).isRequired,
};
SingleLineChart.defaultProps = {
	type: "svg",
};

class MACDChart extends React.Component {
	render() {
		const chartoptions = defaultdata.defaultchartoptions.edgechartoptions;
		const { type, data: initialData, width, ratio } = this.props;
		const margin = { left: 0, right: 50, top: 20, bottom: 30 };
		const macdCalculator = macd().options({ fast: 12, slow: 24, signal: 9,}).merge((d, c) => {d.macd = c;}).accessor(d => d.macd);
		const calculatedData = macdCalculator(initialData);
		const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor(d => d.date);
		const { data, xScale, xAccessor, displayXAccessor, } = xScaleProvider(calculatedData);
		const gridWidth = width - margin.left - margin.right;
		const showGrid = true;
		const yGrid = showGrid ? { innerTickSize: -1 * gridWidth, tickStrokeOpacity: 0.2 } : {};
		const macdAppearance = {
			stroke: {
				macd: "#FF0000",
				signal: "#00F300",
			},
			fill: {
				divergence: "#4648ff"
			},
		};
		console.log(data)
		return (
			<div >
			<ChartCanvas height={this.props.height} width={width} ratio={ratio} margin={margin} type={type} seriesName="EdgeForecast"
				data={data} xScale={xScale} xAccessor={xAccessor} displayXAccessor={displayXAccessor} zoomEvent={false} mouseMoveEvent={false} panEvent={false} >
				<Chart id={3} height={this.props.height} yExtents={macdCalculator.accessor()} origin={(w, h) => [w-(width*1.05), h - 400]}  >
					<XAxis axisAt="bottom" orient="bottom" showTicks={true} outerTickSize={1} stroke={chartoptions.axisappearance.stokecolour} opacity={chartoptions.axisappearance.stokeopacity}/>
					<YAxis axisAt="left" orient="left" tickValues={this.props.yaxisticks} ticks={5} {...yGrid} inverted={true} tickStroke={chartoptions.axisappearance.stokecolour}  />
					<MACDSeries yAccessor={d => d.macd} {...macdAppearance} />
					{/* <MACDTooltip origin={[-38, 15]} yAccessor={d => d.macd} options={macdCalculator.options()} appearance={macdAppearance} /> */}
				</Chart>
			</ChartCanvas>
			</div>
		);
	}
}
MACDChart.propTypes = {
	data: PropTypes.array.isRequired,
	width: PropTypes.number.isRequired,
	ratio: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["svg", "hybrid"]).isRequired,
};
MACDChart.defaultProps = {
	type: "svg",
};
MACDChart = fitWidth(MACDChart);



class EdgeForecastChart extends React.Component {
    state = {
        data: defaultdata.defaultdata.chartdata
    }    
	
	formatcandlesdata(chartdata){
		var data = []
		for(var i = 0; i < chartdata.length; i++) {
			data.push({
				'bb': chartdata[i].bb, 
				'low': chartdata[i].low, 
				'date': new Date(chartdata[i].date*1000), 
				'high': chartdata[i].high, 
				'open': chartdata[i].open, 
				'close': chartdata[i].close, 
				'sma12': chartdata[i].sma12, 
				'sma72': chartdata[i].sma72, 
				'volume': chartdata[i].volume })
		}
		return data;
	}
	formatsingledata(chartdata, value){
		var data = []
		for(var i = 0; i < chartdata.length; i++) {
			data.push({
				'date': new Date(chartdata[i].datedata*1000), 
				'close': parseFloat(chartdata[i][value])})
		}
		return data;
	}
	
	pageComponent(charttype, indicatorchartwidth) {
		switch(charttype) {
			case "candles":
				var chartdata = this.formatcandlesdata(this.props.data)
				return (
					<Grid item xs={12}>
						<CandleStickChartWithDarkTheme type="hybrid" data={chartdata} height={this.props.height} showmavs={this.props.showmavs} />                
					</Grid>
				)
			case "singleline":
				var chartdata = this.formatsingledata(this.props.data, this.props.displayitem)
				return (
					<Grid item xs={12}>
						<SingleLineChart type="hybrid" data={chartdata} height={this.props.height} width={indicatorchartwidth} ratio={1} yaxisextent={this.props.chartoptions.yaxisextent} yaxisticks={this.props.chartoptions.yaxisticks} />                
					</Grid>
				)
			case "barchart":
				var chartdata = this.formatsingledata(this.props.data, this.props.displayitem)
				return (
					<Grid item xs={12}>
						<BarChart type="hybrid" data={chartdata} height={this.props.height} width={indicatorchartwidth} ratio={1} yaxisextent={this.props.chartoptions.yaxisextent} yaxisticks={this.props.chartoptions.yaxisticks} />                
					</Grid>
				)
			case "macd":
				var chartdata = this.formatsingledata(this.props.data, this.props.displayitem)
				return (
					<Grid item xs={12}>
						<MACDChart type="hybrid" data={chartdata} height={this.props.height} width={indicatorchartwidth} ratio={1} yaxisextent={this.props.chartoptions.yaxisextent} yaxisticks={this.props.chartoptions.yaxisticks} />                
					</Grid>
				)
		}
	}
	render() {	
		var indicatorchartwidth = window.innerWidth * 0.3515625;
		if (this.state == null) {
			return <div>Loading...</div>
		} else {
			
			return (
				<React.Fragment>
					<Grid container spacing={1}>
						{this.props.showheading ?
							<Grid item xs={12}>
								<HeadingWithInfo heading={this.props.heading} headingclassname="edgeforecast_predheading" description={defaultdata.defaultdescriptions.pricechartdescription} />
							</Grid>
						:
							<Grid item xs={12} ></Grid>
						}
						{this.pageComponent(this.props.charttype, indicatorchartwidth)}
					</Grid>
				</React.Fragment>
            
		)}
	}
}
export default EdgeForecastChart;



