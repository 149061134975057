import React from "react";
import Grid from '@material-ui/core/Grid';
import Chart from "react-apexcharts";
import HeadingWithInfo from "../general/headingwithinfo";
import NumberFormatter from "../../utils/numberformater";
import heatsquare_lvl1 from '../../assets/images/heatsquare_lvl1.png'
import heatsquare_lvl2 from '../../assets/images/heatsquare_lvl2.png'
import heatsquare_lvl3 from '../../assets/images/heatsquare_lvl3.png'
import heatsquare_lvl4 from '../../assets/images/heatsquare_lvl4.png'
import heatsquare_lvl5 from '../../assets/images/heatsquare_lvl5.png'
import heatsquare_lvl6 from '../../assets/images/heatsquare_lvl6.png'
import heatsquare_lvl7 from '../../assets/images/heatsquare_lvl7.png'
import heatsquare_lvl8 from '../../assets/images/heatsquare_lvl8.png'
import heatsquare_lvl9 from '../../assets/images/heatsquare_lvl9.png'
import heatsquare_lvl10 from '../../assets/images/heatsquare_lvl10.png'
import heatsquare_none from '../../assets/images/heatsquare_none.png'
import "../../assets/css/main.css"
import defaultdata from "../general/defaultdata";

class DashboardWidget extends React.Component {
    constructor(props) {
        super(props);
        this.widgetcomponent = this.widgetcomponent.bind(this);    
    }
    formatbarchartdata(data) {           
        var newdata = []
        for(var i = 0; i < data['data'].length; i++){
            newdata.push(parseFloat((data['data'][i]*100).toFixed(4)))
        }        
        return {'data': newdata}
    }
    widgetcomponent() {
        var chartHeight = 115;
        var radialOptions = defaultdata.defaultchartoptions.radialwidgetoptionsSml;
        var gaugeOptions = defaultdata.defaultchartoptions.gaugewidgetoptionsSml;
        var volchangeOptions = defaultdata.defaultchartoptions.volumechangesoptionsSml;
        if(window.innerHeight>1280) { 
            chartHeight=150; 
            radialOptions = defaultdata.defaultchartoptions.radialwidgetoptionsLrg;
            gaugeOptions = defaultdata.defaultchartoptions.gaugewidgetoptionsLrg;
            volchangeOptions = defaultdata.defaultchartoptions.volumechangesoptionsLrg;
        }
        switch (this.props.widget) {
            case "heatsnapshot":
                return (                    
                    <Grid container spacing={0}>
                        <Grid item xs={4}>
                            <div className="edgeforecast_catheading">{this.props.heading} </div>
                        </Grid>                        
                        <Grid item xs={6}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={1}>
                                            <img src={this.props.value >=this.props.thresholds['lvl1'] ? heatsquare_lvl1 : heatsquare_none} className="heat_image" />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <img src={this.props.value >=this.props.thresholds['lvl2'] ? heatsquare_lvl2 : heatsquare_none} className="heat_image" />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <img src={this.props.value >=this.props.thresholds['lvl3'] ? heatsquare_lvl3 : heatsquare_none} className="heat_image" />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <img src={this.props.value >=this.props.thresholds['lvl4'] ? heatsquare_lvl4 : heatsquare_none} className="heat_image" />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <img src={this.props.value >=this.props.thresholds['lvl5'] ? heatsquare_lvl5 : heatsquare_none} className="heat_image" />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <img src={this.props.value >=this.props.thresholds['lvl6'] ? heatsquare_lvl6 : heatsquare_none} className="heat_image" />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <img src={this.props.value >=this.props.thresholds['lvl7'] ? heatsquare_lvl7 : heatsquare_none} className="heat_image" />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <img src={this.props.value >=this.props.thresholds['lvl8'] ? heatsquare_lvl8 : heatsquare_none} className="heat_image" />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <img src={this.props.value >=this.props.thresholds['lvl9'] ? heatsquare_lvl9 : heatsquare_none} className="heat_image" />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <img src={this.props.value >=this.props.thresholds['lvl0'] ? heatsquare_lvl10 : heatsquare_none} className="heat_image" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>                        
                        </Grid>
                        <Grid item xs={1} >
                            <div className="edgeforecast_catheading">{this.props.value}</div>
                        </Grid>
                    </Grid>
                );
            case "radial":
                return (                    
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <HeadingWithInfo heading={this.props.heading} headingclassname="edgeforecast_catheading" description={this.props.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <Chart series={[NumberFormatter(this.props.value[0])]} options={radialOptions} type="radialBar" height={chartHeight}/>                            
                        </Grid>
                    </Grid>                    
                );
            case "gauge":
                return (
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <HeadingWithInfo heading={this.props.heading} headingclassname="edgeforecast_catheading" description={this.props.description} />
                        </Grid>
                        <Grid item xs={12}>
                          <Chart series={[NumberFormatter(this.props.value[0])]} options={gaugeOptions} type="radialBar" height={chartHeight+25}/>
                            {/* <GaugeChart id={"widgetgauge_"+this.props.index} animDelay={0} 
                              arcsLength={[this.props.value/100, 1-(this.props.value/100)]} 
                              colors={this.props.options.colors} animate={false} 
                              arcWidth={this.props.options.arcWidth} percent={this.props.value/100} 
                              formatTextValue={value => NumberFormatter(value) + '% mx/tpm'} 
                              style={this.props.height} fontSize={fontsize} needleColor="#505050"/> */}
                            
                        </Grid>
                    </Grid>
                    
                );
            case "bar":
                return (
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <HeadingWithInfo heading={this.props.heading} headingclassname="edgeforecast_catheading" description={this.props.description} />
                            </Grid>
                            <Grid item xs={12}>
                                <Chart series={[this.formatbarchartdata(this.props.value)]} options={this.props.options} type="bar" height={80}/>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            case "barsplit":
                // console.log(this.props)
                return (
                  <Grid item xs={12}>
                      <Grid container spacing={0}>
                          <Grid item xs={12}>
                              <HeadingWithInfo heading={this.props.heading} headingclassname="edgeforecast_catheading" description={this.props.description} />
                          </Grid>
                          <Grid item xs={12}>
                              <Chart series={this.props.value} options={volchangeOptions} type="bar" height={80}/>
                          </Grid>
                      </Grid>
                  </Grid>
                );
            default:
                return (
                    <Grid item xs={12} >                        
                    </Grid>
                );
        }
    }

    render() {
    //   console.log(this.props)
        return (
            <React.Fragment>
                {this.widgetcomponent()}                
            </React.Fragment>
        );
    }
}

export default DashboardWidget