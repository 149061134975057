import React from "react";
import { Helmet } from 'react-helmet'
import { Checkbox, Grid } from '@material-ui/core';
import SearchListItem from '../edgeforecaster/searchlistitem'
import { BiUser} from "react-icons/bi";
import searchitem from "../../assets/images/search.png"
import "react-pro-sidebar/dist/css/styles.css";
import "../../assets/css/main.css"

class ProfilePage extends React.Component {  
  state = { 
    usersettings: null,    
   }

  componentDidMount() {
    
  }
  
  render() {
    const siteTitle = 'EdgeProfle'
    const isLoading = false;    
    console.log(this.props)
    return (
        <React.Fragment>
            <Helmet>
            <title>EdgeForecaster - EdgeProfile</title>
            <meta name="description" content="EdgeForecaster - EdgeProfile" />
            </Helmet>
            <div id="main" className="wrapper style1">
              <div className="container">          
                <div className="row gtr-150">            
                  <div className="col-12 col-12-medium imp-medium">
                    <section id="content">
                      <Grid container spacing={1}>
                        <Grid item xs={8}>
                          <h1>EdgeProfile</h1>                      
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>                    
                        <Grid item xs={12} className="profile_cont">
                          <Grid container spacing={1}>                    
                            <Grid item xs={7}>
                              <Grid container spacing={1} className="profile_detailscont">
                                <Grid item xs={4}>
                                  <div className="profile_img"><BiUser /></div>
                                </Grid>
                                <Grid item xs={8}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                      <div className="profileinfo_heading"> Name: </div>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <div className="profileinfo_detail"> {this.props.userdetails.firstname +" "+this.props.userdetails.lastname} </div>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                      <div className="profileinfo_heading"> Email: </div>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <div className="profileinfo_detail"> {this.props.userdetails.email} </div>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                      <div className="profileinfo_heading"> Location: </div>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <div className="profileinfo_detail"> {this.props.userdetails.addr_location} </div>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                      <div className="profileinfo_heading"> Subscription: </div>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <div className="profileinfo_detail"> {this.props.userdetails.customertype.charAt(0).toUpperCase() + this.props.userdetails.customertype.slice(1)} </div>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                      <div className="profileinfo_heading"> Benefit Points: </div>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <div className="profileinfo_detail"> {this.props.userdetails.benefitpoints} </div>
                                    </Grid>
                                  </Grid>
                                </Grid>                            
                              </Grid>
                            </Grid>
                            <Grid item xs={5}/>
                          </Grid>                        
                        </Grid>
                        <Grid item xs={12} className="profile_symbollist">
                          <div className="table-container">
                            <div className="uk-overflow-auto">
                              <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
                                <tbody>
                                  <tr>
                                    <th>
                                    <Grid container spacing={0}>
                                      <Grid item xs={2} className="settings_searchtable_headings" style={{textAlign:"start"}} >Name</Grid>
                                      <Grid item xs={1} className="settings_searchtable_headings" > </Grid>
                                      <Grid item xs={1} className="settings_searchtable_headings" >Last Price</Grid>
                                      <Grid item xs={1} className="settings_searchtable_headings" >24hr %Change</Grid>
                                      <Grid item xs={1} className="settings_searchtable_headings" >Trades/min</Grid>
                                      <Grid item xs={1} className="settings_searchtable_headings" > </Grid>
                                      <Grid item xs={2} className="settings_searchtable_headings" >Forecastability</Grid>
                                      <Grid item xs={1} className="settings_searchtable_headings" >Shortterm</Grid>
                                      <Grid item xs={1} className="settings_searchtable_headings" >Longterm</Grid>
                                      <Grid item xs={1} className="settings_searchtable_headings" >Selected </Grid>
                                    </Grid>
                                    </th>
                                  </tr>
                                {isLoading ?
                                  <tr><td colSpan={6} className="uk-text-center"><em className="uk-text-muted">Loading...</em></td></tr>
                                :                                   
                                  this.props.usersettings.map((settingdata, index) => {
                                    return (
                                    <tr key={index} >
                                      <td>
                                        <SearchListItem searchitem={settingdata} />                                        
                                      </td>
                                      </tr>)
                                      })
                                  }
                                  </tbody>
                              </table>
                            </div>
                            </div>
                        </Grid>
                      </Grid>
                    </section>
                  </div>
                </div>
              </div>
            </div>
        </React.Fragment>
    );    
  }
}

export default ProfilePage