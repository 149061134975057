import React from "react";
import Grid from '@material-ui/core/Grid';
import DashboardDataItem from "./dashboarddataitem";
import HeadingWithInfo from "../general/headingwithinfo";
import "../../assets/css/main.css"

class DataTableSml extends React.Component {    
    render() {
        //console.log(this.props)    
        return (
            <React.Fragment>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                            <HeadingWithInfo heading={this.props.tableheading} headingclassname="edgeforecast_catheading" description={this.props.tabledesc} />
                    </Grid> 
                    {this.props.dataitems.map((dataitem, index) => {
                        return (                        
                            dataitem.map((dtaitm, indx) => {
                                return (
                                    <Grid item xs={this.props.griditemsize} key={indx}>
                                        <DashboardDataItem key={index} 
                                            colour={dtaitm.colour} value={dtaitm.value} 
                                            itemid={dtaitm.itemid} descriptions={this.props.descriptions}
                                            selectedactivechartitem={this.props.selectedactivechartitem} 
                                            activechartitemslist={this.props.activechartitemslist}/>
                                    </Grid>
                                )
                            })
                        )}
                    )}                                 
                </Grid>
            </React.Fragment>
        );
    }
}

export default DataTableSml