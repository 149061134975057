import React, { Component } from "react";
import PropTypes from "prop-types";
import GenericChartComponent from "react-stockcharts/lib/GenericChartComponent";
import { getMouseCanvas } from "react-stockcharts/lib/GenericComponent";
import { sum } from "d3-array";

import {
  first,
  last,
  isNotDefined,
  isDefined,
  hexToRGBA
} from "react-stockcharts/lib/utils";
import { isArray, isObject, isString } from "util";

class DepthHoverTooltip extends Component {
  constructor(props) {
    super(props);
    this.renderSVG = this.renderSVG.bind(this);
    this.drawOnCanvas = this.drawOnCanvas.bind(this);
  }
  drawOnCanvas(ctx, moreProps) {
    const pointer = helper(this.props, moreProps, ctx);
    if (isNotDefined(pointer)) return;

    const { height } = moreProps;
    drawOnCanvas(ctx, this.props, this.context, pointer, height, moreProps);
    return;
  }
  renderSVG(moreProps) {
    const pointer = helper(this.props, moreProps);

    if (isNotDefined(pointer)) return null;

    const {
      r,
      lineStroke,
      backgroundShapeSVG,
      tooltipSVG,
      className
    } = this.props;
    const { bgheight, bgwidth } = this.props;
    const { height, currentItem } = moreProps;

    const { x, y, content, bgSize } = pointer;
    const bgShape =
      isDefined(bgwidth) && isDefined(bgheight)
        ? { ...bgSize, width: bgwidth, height: bgheight }
        : bgSize;
    const linestroke = isString(lineStroke)
      ? lineStroke
      : lineStroke(currentItem);
    const xAdjust = bgSize.reverse ? -1 : 1;
    return (
      <g>
        <circle className={className} cx={x} cy={y} r={r} fill={linestroke} />
        <circle
          className={className}
          cx={x}
          cy={y}
          r={r + 2}
          stroke={linestroke}
          fill={"transparent"}
        />
        <line
          x1={x}
          y1={y}
          x2={x}
          y2={height}
          stroke={linestroke}
          strokeDasharray={"3"}
        />
        <line
          x1={x + (STROKEWIDTH / 2) * xAdjust}
          y1={bgShape.y}
          x2={x + (STROKEWIDTH / 2) * xAdjust}
          y2={bgShape.y + bgShape.height}
          stroke={linestroke}
          strokeWidth={STROKEWIDTH}
        />
        <g
          className="react-stockcharts-tooltip-content"
          transform={`translate(${bgShape.x + (STROKEWIDTH + 1) * xAdjust}, ${
            bgShape.y
          })`}
        >
          {backgroundShapeSVG(this.props, bgShape)}
          {tooltipSVG(this.props, bgShape, content)}
        </g>
      </g>
    );
  }
  render() {
    return (
      <GenericChartComponent
        svgDraw={this.renderSVG}
        canvasDraw={this.drawOnCanvas}
        canvasToDraw={getMouseCanvas}
        drawOn={["mousemove", "pan", "drag"]}
        {...this.props}
      />
    );
  }
}

DepthHoverTooltip.propTypes = {
  chartId: PropTypes.number,
  yAccessor: PropTypes.func,
  tooltipSVG: PropTypes.func,
  backgroundShapeSVG: PropTypes.func,
  bgwidth: PropTypes.number,
  bgheight: PropTypes.number,
  bgFill: PropTypes.string.isRequired,
  bgOpacity: PropTypes.number.isRequired,
  lineStroke: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    .isRequired,
  lineOpacity: PropTypes.number.isRequired,
  tooltipContent: PropTypes.func.isRequired,
  origin: PropTypes.oneOfType([PropTypes.array, PropTypes.func]).isRequired,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.number,
  r: PropTypes.number.isRequired,
  className: PropTypes.string
};

DepthHoverTooltip.contextTypes = {
  margin: PropTypes.object.isRequired,
  ratio: PropTypes.number.isRequired
};

DepthHoverTooltip.defaultProps = {
  tooltipSVG: tooltipSVG,
  tooltipCanvas: tooltipCanvas,
  origin: origin,
  fill: "#D4E2FD",
  bgFill: "#D4E2FD",
  bgOpacity: 0,
  lineStroke: "#D4E2FD",
  lineOpacity: 1,
  stroke: "#9B9BFF",
  fontFill: "#000000",
  opacity: 0,
  backgroundShapeSVG: backgroundShapeSVG,
  backgroundShapeCanvas: backgroundShapeCanvas,
  fontFamily: "sans-serif, Helvetica Neue, Helvetica, Arial, sans-serif",
  fontSize: 12,
  r: 3,
  className: "react-stockcharts-current-coordinate"
};

const STROKEWIDTH = 5;
const PADDING = 5;
const X = 6;
const Y = 6;
/* eslint-disable react/prop-types */
function backgroundShapeSVG({ fill, stroke, opacity }, { height, width }) {
  return (
    <rect
      height={height}
      width={width}
      fill={fill}
      stroke={stroke}
      opacity={opacity}
    />
  );
}
function tooltipSVG(
  { fontFamily, fontSize, fontFill },
  { labelwidth },
  content
) {
  const tspans = [];
  const startY = Y + fontSize * 0.9;
  let labelFontSize = fontSize;
  for (let i = 0; i < content.y.length; i++) {
    const y = content.y[i];
    if (y.fontSize) labelFontSize = y.fontSize;

    const textY = startY + fontSize * (i + 1);

    const style = { fontFamily: fontFamily, fontSize: labelFontSize };

    tspans.push(
      <tspan key={`L-${i}`} x={X} y={textY} style={style} fill={y.stroke}>
        {y.label}
      </tspan>
    );
    tspans.push(
      <tspan key={`V-${i}`} x={X + labelwidth} style={style}>
        {y.value}
      </tspan>
    );
  }
  return (
    <text fontFamily={fontFamily} fontSize={fontSize} fill={fontFill}>
      <tspan x={X} y={startY}>
        {content.x}
      </tspan>
      {tspans}
    </text>
  );
}

/* eslint-enable react/prop-types */

function backgroundShapeCanvas(props, { width, height }, ctx) {
  const { fill, stroke, opacity } = props;
  ctx.beginPath();
  ctx.rect(0, 0, width, height);
  ctx.fillStyle = hexToRGBA(fill, opacity);
  ctx.fill();
  ctx.lineWidth = 1;
  ctx.strokeStyle = stroke;
  ctx.stroke();
}

function tooltipCanvas(
  { fontFamily, fontSize, fontFill },
  content,
  ctx,
  { labelwidth }
) {
  const startY = Y + fontSize * 0.9;
  // const width = reverse ? -STROKEWIDTH : STROKEWIDTH;

  ctx.font = `${fontSize}px ${fontFamily}`;
  ctx.fillStyle = fontFill;
  ctx.fillText(content.x, X, startY);

  let labelFontSize = fontSize;
  for (let i = 0; i < content.y.length; i++) {
    let y = content.y[i];
    if (y.fontSize) labelFontSize = y.fontSize;
    let textY = startY + fontSize * (i + 1);
    ctx.font = `${labelFontSize}px ${fontFamily}`;
    ctx.fillStyle = y.stroke || fontFill;
    ctx.fillText(y.label, X, textY);

    ctx.fillStyle = fontFill;
    ctx.fillText(y.value, X + labelwidth, textY);
  }
}

function drawOnCanvas(ctx, props, context, pointer, height, moreProps) {
  const { margin, ratio } = context;
  const { r, lineStroke, lineOpacity } = props;
  const { backgroundShapeCanvas, tooltipCanvas } = props;
  const { currentItem } = moreProps;

  const originX = 0.5 * ratio + margin.left;
  const originY = 0.5 * ratio + margin.top;

  ctx.save();

  ctx.setTransform(1, 0, 0, 1, 0, 0);
  ctx.scale(ratio, ratio);
  ctx.translate(originX, originY);

  const { x, y, content, bgSize } = pointer;
  const linestroke = isString(lineStroke)
    ? lineStroke
    : lineStroke(currentItem);
  const stroke = hexToRGBA(linestroke, lineOpacity);

  const xAdjust = bgSize.reverse ? -1 : 1;

  ctx.fillStyle = stroke;
  ctx.beginPath();
  ctx.arc(x, y, r, 0, 2 * Math.PI, false);
  ctx.fill();

  ctx.strokeStyle = stroke;
  ctx.beginPath();
  ctx.arc(x, y, r + 2, 0, 2 * Math.PI);
  ctx.stroke();

  ctx.beginPath();
  ctx.setLineDash([3]);
  ctx.moveTo(x, y);
  ctx.lineTo(x, height);
  ctx.stroke();

  ctx.beginPath();
  ctx.setLineDash([]);
  ctx.moveTo(x + (STROKEWIDTH / 2) * xAdjust, bgSize.y);
  ctx.lineTo(x + (STROKEWIDTH / 2) * xAdjust, bgSize.y + bgSize.height);
  ctx.lineWidth = STROKEWIDTH;
  ctx.stroke();

  ctx.translate(bgSize.x + STROKEWIDTH * xAdjust, bgSize.y);
  backgroundShapeCanvas(props, bgSize, ctx);
  tooltipCanvas(props, content, ctx, bgSize);

  ctx.restore();
}

function calculateTooltipSize(
  { fontFamily, fontSize, fontFill },
  content,
  ctx
) {
  if (isNotDefined(ctx)) {
    const canvas = document.createElement("canvas");
    ctx = canvas.getContext("2d");
  }
  ctx.font = `${fontSize}px ${fontFamily}`;
  ctx.fillStyle = fontFill;
  ctx.textAlign = "left";
  const measureTextWidth = str => ctx.measureText(str).width;
  const measureText = (label, str) => {
    return {
      labelwidth: measureTextWidth(label),
      width: measureTextWidth(str),
      height: fontSize
    };
  };

  const { width, height, labelwidth } = content.y
    .map(({ label, value, ...rest }) => {
      const lableFontFamily = rest.fontFamily || fontFamily;
      const lableFontSize = rest.fontSize || fontSize;
      ctx.font = `${lableFontSize}px ${lableFontFamily}`;
      return measureText(`${label}  `, `${value}`);
    })
    // Sum all y and x sizes (begin with x label size)
    .reduce(
      (res, size) => sumSizes(res, size),
      measureText("", String(content.x))
    );
  return {
    labelwidth,
    width: width + 2 * X,
    height: height + 2 * Y
  };
}

function sumSizes(...sizes) {
  const labelWidth = Math.max(...sizes.map(size => size.labelwidth));
  return {
    labelwidth: labelWidth,
    width: labelWidth + Math.max(...sizes.map(size => size.width)),
    height: sum(sizes, d => d.height)
  };
}

function normalizeX(x, bgSize, pointWidth, width) {
  // return x - bgSize.width - pointWidth / 2 - PADDING * 2 < 0
  if (x < width / 2) {
    return { x: x, reverse: false };
  } else {
    return { x: x - bgSize.width, reverse: true };
  }
}

function normalizeY(y, bgSize, r, height) {
  if (y + bgSize.height + r * 2 > height) {
    return { y: y - (bgSize.height + PADDING + r), ySign: -1 };
  } else {
    return { y: y + PADDING + r, ySign: 1 };
  }
}

function origin(props, moreProps, bgSize, pointWidth) {
  const { chartId, yAccessor, r } = props;
  const {
    mouseXY,
    xAccessor,
    currentItem,
    xScale,
    chartConfig,
    width,
    height
  } = moreProps;
  let y = last(mouseXY);

  const xValue = xAccessor(currentItem);
  let x = Math.round(xScale(xValue));
  if (isDefined(chartId) && isDefined(yAccessor)) {
    const yValue = yAccessor(currentItem);
    if (chartConfig) {
      if (isObject(chartConfig)) {
        y = Math.round(chartConfig.yScale(yValue));
      } else if (isArray(chartConfig)) {
        const chartIndex = chartConfig.findIndex(x => x.id === chartId);
        y = Math.round(chartConfig[chartIndex].yScale(yValue));
      }
    }
  }
  const xPoint = normalizeX(x, bgSize, pointWidth, width);
  const yPoint = normalizeY(y, bgSize, r, height);
  const point = { ...xPoint, ...yPoint };
  return [x, y, point];
}

function helper(props, moreProps, ctx) {
  const { show, xScale, currentItem, plotData } = moreProps;
  const { origin, tooltipContent } = props;
  const { xAccessor, displayXAccessor } = moreProps;

  if (!show || isNotDefined(currentItem)) return;

  const xValue = xAccessor(currentItem);
  if (!show || isNotDefined(xValue)) return;

  const content = tooltipContent({ currentItem, xAccessor: displayXAccessor });
  if (!show || isNotDefined(content)) return;

  const centerX = xScale(xValue);
  const pointWidth =
    Math.abs(
      xScale(xAccessor(last(plotData))) - xScale(xAccessor(first(plotData)))
    ) /
    (plotData.length - 1);

  const bgSize = calculateTooltipSize(props, content, ctx);

  const [x, y, point] = origin(props, moreProps, bgSize, pointWidth);

  return {
    x,
    y,
    content,
    centerX,
    pointWidth,
    bgSize: { ...bgSize, ...point }
  };
}

export default DepthHoverTooltip;