import React from "react";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { FiInfo } from "react-icons/fi";
import "../../assets/css/main.css"

class HeadingWithInfo extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
        expanded: false,           
      }
    }
    render() {        
        return (
            <React.Fragment>
                
                <PopupState variant="popover">
                    {(popupState) => (
                        <div>
                            <div className={this.props.headingclassname} {...bindTrigger(popupState)}>
                                {this.props.heading}
                                <FiInfo/>
                            </div>
                            <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                                }}
                                transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                                }}>
                                <Box p={2}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <div className="edgeforecast_info_heading">
                                                {this.props.heading}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="edgeforecast_info_detail">
                                                {this.props.description}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Popover>
                        </div>
                    )}
                </PopupState>
            </React.Fragment>
        );
    }
}

export default HeadingWithInfo