import React from "react";
import Grid from '@material-ui/core/Grid';
import DashboardWidget from "./dashboardwidget";
import "../../assets/css/main.css"

class DashboardSml extends React.Component {    
    render() {
        // console.log(this.props)
        return (
            <React.Fragment>
                {this.props.vertical ?
                    <Grid item xs={12} >
                        {this.props.widgets.map((dashwidget, index) => {
                            // console.log(index)
                            // console.log(dashwidget)
                            return (
                                <DashboardWidget key={index} index={index} 
                                    widget={dashwidget.widget} 
                                    heading={dashwidget.heading} 
                                    description={dashwidget.description} 
                                    options={dashwidget.options} 
                                    value={dashwidget.value} />
                            )
                        })}                        
                    </Grid>
                :
                    <Grid item xs={5}>
                        {this.props.widgets.map((dashwidget, index) => {                            
                            return (                                
                                <Grid item xs={6} style={{float:"left"}} key={index}> 
                                    <DashboardWidget index={index} 
                                        widget={dashwidget.widget} 
                                        heading={dashwidget.heading} 
                                        description={dashwidget.description} 
                                        options={dashwidget.options} 
                                        value={dashwidget.value} />
                                </Grid>
                            )
                        })}                       
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default DashboardSml