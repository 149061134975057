import React from "react";
import Grid from '@material-ui/core/Grid';
import HeadingWithInfo from "../general/headingwithinfo";
import "../../assets/css/main.css"

class MarketDepthItem extends React.Component {
    render() {        
        return (
            <React.Fragment>                
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <div className={this.props.priceclassname}>{this.props.price}</div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="edgeforecast_md_data_qty">{this.props.qty}</div>
                    </Grid>
                </Grid>                        
            </React.Fragment>
        );
    }
}

class MarketDepthData extends React.Component {    
    render() {
        return (
            <React.Fragment>
                <Grid container spacing={0}>
                    <Grid item xs={12} className="edgeforecast_md">
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <HeadingWithInfo heading={this.props.heading} headingclassname="edgeforecast_catheading" description={this.props.description} />
                            </Grid>                                                        
                            {this.props.depthdata.map((depthlevel, index) => {
                                return <MarketDepthItem key={index} priceclassname={depthlevel.priceclassname} price={depthlevel.price} qty={depthlevel.volume} />
                            })}
                        </Grid>
                    </Grid>                    
                </Grid>
            </React.Fragment>
        );
    }
}

export default MarketDepthData