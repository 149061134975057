import React from "react";
import NumberFormatter from "../../utils/numberformater";
import Grid from '@material-ui/core/Grid';
import "../../assets/css/main.css"

const forecastabilitydescription = "Forecastability is a measure out of 100 as to how accuracte the Edgeforecaster forecasts have been."

class ItemDetails extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
        expanded: false,           
      }
    }
    render() {        

        var percentchange = NumberFormatter(this.props.change24Hr.value/this.props.lastprice.value)
        return (
            <React.Fragment>
                <Grid container spacing={0}>
                    <Grid item xs={4}>
                        <div className="edgeforecast_symbol">{this.props.edgename}</div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className="edgeforecast_exchange" style={{color:"#aaaaaa"}}>{this.props.exchange}</div>
                    </Grid>
                    <Grid item xs={4}>                        
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={4}>
                        <div className="edgeforecast_pricedata">{NumberFormatter(this.props.lastprice.value)}</div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className="edgeforecast_pricedata" style={{color:this.props.change24Hr.colour}}>
                            {NumberFormatter(this.props.change24Hr.value)}</div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className="edgeforecast_pricedata" style={{color:this.props.change24Hr.colour}}>{percentchange+'%'}</div>
                    </Grid>                    
                </Grid>
                <Grid container spacing={0} style={{height:"5vmin"}}></Grid>
            </React.Fragment>
        );
    }
}

export default ItemDetails