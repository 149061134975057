import React from "react";
import Grid from '@material-ui/core/Grid';
import { slideDown, slideUp } from './anim';
import { ImCircleRight, ImCircleDown, ImCircleUp} from "react-icons/im";
import defaultdata from "../general/defaultdata";
import EdgeForecastChart from "./edgechart";
import ForecastabilityItem from "./forecastability_item"
import DataTableSml from "./datatable_sml"
import MarketDepthData from "./marketdepthdata";
import DashboardSml from "./dashboard_sml";
import MarketDataChart from "./marketdatachart";
import ForecastDisplay from "./forecastdisplayitem"
import ItemDetails from "./itemdetails";
import EdgeRowWidgets from "./edgerowwidgets";
import Spinner from 'react-spinner-material';

import "react-pro-sidebar/dist/css/styles.css";
import "../../assets/css/main.css"


class SymbolEdgeRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      expanded: false,
      loading: true,
      dataloaded: false,
      counter: 0,
      settingdata: this.props.settingdata,      
      descriptions: defaultdata.defaultdescriptions,
      depthdata: defaultdata.defaultdata.depthdata,
      chartdata: defaultdata.defaultdata.chartdata,
      displaychartdata: defaultdata.defaultdata.displaychartdata,
      generaldata: defaultdata.defaultdata.generaldata,
      forecastdata: defaultdata.defaultdata.forecastdata,
      widgetdata: defaultdata.defaultdata.widgetdata,
      activechartitem: {
        lastprice: false,
        change24Hr: false,
        high24Hr: false,
        low24Hr: false,
        totaltrades: false,
        beta: false,
        value24Hr: false,
        volume24Hr: false,
        volumepermin: false,
        changepermin: false,
        tradespermin: false,
        sharpe: false,
        rsi: true,
        macd: false,
        mfi: false,
        stoch: false,
        volatility: false,
        sortino: false
      }
    }
    this.loadSymbolDetail = this.loadSymbolDetail.bind(this)
    this.selectedactivechartitem = this.selectedactivechartitem.bind(this)
    this.updatedata = this.updatedata.bind(this);
  }

  loadSymbolDetail(){
    this.props.setdata(this.state);
    this.props.switchComponent("SymbolDetail");
  }
  toggleExpander = (e) => {
    if (e.target.type === 'checkbox') return;

    if (!this.state.expanded) {
      this.setState(
        { expanded: true },
        () => {
          if (this.refs.expanderBody) {
            slideDown(this.refs.expanderBody);
          }
        }
      );
    } else {
      slideUp(this.refs.expanderBody, {
        onComplete: () => { this.setState({ expanded: false }); }
      });
    }
  }
  selectedactivechartitem(itemname) {
    console.log(itemname)
    console.log(this.state.activechartitem[itemname])
  }
  updatedata(){
    let headers = new Headers();
    headers.append('Content-Type', 'text/html');    
    headers.append('Access-Control-Allow-Origin','*');
    headers.append('Origin','*');     
    // headers.append('Origin','https://app.edgeforecaster.com/');
    // headers.append('Origin','http://localhost:3000');
    // headers.append('Origin','https://master.dw3hg721lpgmj.amplifyapp.com/');
    

    if(!this.state.loading){
      if(!this.state.dataloaded) {
        console.log('https://'+ this.props.settingdata.dataendpoint+'/getsymbolfulldata')
        fetch('https://'+ this.props.settingdata.dataendpoint+'/getsymbolfulldata', { 
          method: 'get', headers: headers, mode: 'cors'
        }).then(response => response.json()
        ).then((data) => {
          // console.log(data)
          var oldState = this.state;
          var newState = {
            ...oldState,
            depthdata: data.depthdata,
            chartdata: data.chartdata,
            displaychartdata: data.displaychartdata,
            generaldata: data.generaldata,
            forecastdata: data.forecastdata,
            widgetdata: data.widgetdata,
            counter: 50,
            dataloaded: true

          }
          this.setState(newState)
        }).catch((error) => {
          console.log("Fetch User Error");
          console.log(error)
        });
      }
      else {
        if(this.props.status === "Edge"){
          if(this.state.counter >=80){
            fetch('https://'+ this.props.settingdata.dataendpoint+'/getsymbolfulldata', { 
              method: 'get', headers: headers, mode: 'cors'
            }).then(response => response.json()
            ).then((data) => {
              // console.log(data)
              var oldState = this.state;
              var newState = {
                ...oldState,
                depthdata: data.depthdata,
                chartdata: data.chartdata,
                displaychartdata: data.displaychartdata,
                generaldata: data.generaldata,
                forecastdata: data.forecastdata,
                widgetdata: data.widgetdata,
                counter: 0

              }
              this.setState(newState)
              // console.log(data)
            }).catch((error) => {
              console.log("Fetch User Error");
              console.log(error)
            });
          } else {
            fetch('https://'+ this.props.settingdata.dataendpoint+'/getsymbollivedata', { 
              method: 'get', headers: headers, mode: 'cors'
            }).then(response => response.json()
            ).then((data) => {            
              var newcounter = this.state.counter+1;
              var oldState = this.state;
              var newState = {
                ...oldState,
                depthdata: data.depthdata,
                // chartdata: data.chartdata,
                // displaychartdata: data.displaychartdata,
                generaldata: data.generaldata,
                // forecastdata: data.forecastdata,
                // widgetdata: data.widgetdata,
                counter: newcounter

              }
              this.setState(newState)
            }).catch((error) => {
              console.log("Fetch User Error");
              console.log(error)
            });
          }
        }
      }
      // console.log(this.state.counter)
    }
    // console.log(this.state)
  }
  componentDidMount() {
    if(this.props.settingdata) {
      
      this.setState({loading: false, settingdata:this.props.settingdata});
      setInterval(()=>{ this.updatedata()}, 500);
    }
  }

  render() {
    // console.log(this.props.settingdata)
    var chartheight = window.innerHeight;
    // console.log(chartheight)
    return (
      <React.Fragment>
        {this.state.loading
          ? <tr>
              <td colSpan={6} className="uk-text-center">                                          
                <Grid container spacing={0}>
                  <Grid item xs={6} />
                  <Grid item xs={1} className="loadingspinnerrow">
                    <Spinner radius={75} color={"#4648ff"} stroke={2} visible={true} />
                  </Grid>
                  <Grid item xs={6} />
                </Grid>
                
              </td>
            </tr>
          :
            [
            <tr key={this.props.index} >
              <td>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={10} style={{backgroundColor: "#272833"}}>
                          <Grid container spacing={1}>
                            <Grid item xs={3}>
                              <Grid container spacing={0}>
                                <Grid item xs={12}>
                                  <ItemDetails edgename={this.props.settingdata.edgename} 
                                    exchange={this.props.settingdata.exchange}
                                    lastprice={this.state.generaldata[1]}
                                    change24Hr={this.state.generaldata[2]}/>
                                </Grid>
                              </Grid>
                              <Grid container spacing={0}>
                                  <Grid item xs={12}>
                                      <ForecastabilityItem forecastability={this.state.forecastdata.forecastability} 
                                      accuracychartseries={this.state.forecastdata.accuracy  } accuracychartoptions={defaultdata.defaultchartoptions.accuracychartoptions} />           
                                  </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={3}>
                              <div className="edgeforecast_datacontainer" >
                                <DataTableSml griditemsize={6} dataitems={[this.state.generaldata.slice(1,3),
                                  this.state.generaldata.slice(3,5), this.state.generaldata.slice(5,7), 
                                  this.state.generaldata.slice(7,9)]} activechartitemslist={this.state.activechartitem}
                                  descriptions={defaultdata.defaultdescriptions} selectedactivechartitem={this.selectedactivechartitem} 
                                  tableheading = {"Overview"} tabledesc={"To be done"}/>
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <div className="edgeforecast_datacontainer" >
                                <EdgeRowWidgets widgetdata={this.state.widgetdata} 
                                  generaldata={this.state.generaldata} 
                                  descriptions={defaultdata.defaultdescriptions} 
                                  chartoptions={defaultdata.defaultchartoptions} />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={2} style={{backgroundColor: "#272833"}}>
                          <ForecastDisplay forecastitems={this.state.forecastdata.forecasts} vertical={false} />
                          <Grid container spacing={0} className="edgeforecast_navarrowcontainer">
                            <Grid item xs={10}>
                              <div className="edgeforecast_nav_arrowleft" onClick={this.toggleExpander}>
                                {this.state.expanded ? 
                                  <ImCircleUp  />
                                  :
                                  <ImCircleDown  />
                                }
                              </div>
                            </Grid>
                            <Grid item xs={2}>
                              <div className="edgeforecast_nav_arrowright" onClick={this.loadSymbolDetail} ><ImCircleRight /></div>
                            </Grid>
                          </Grid>
                        </Grid>                
                      </Grid>
                    </Grid>
                </Grid>
              </td>
            </tr>,
            this.state.expanded && (        
              <tr className="expandable" key="tr-expander" style={{minHeight:"50vh"}}>
                <td className="uk-background-muted" colSpan={6}>
                  <div className="edgeforecast_container_expanded">
                    <div ref="expanderBody" className="inner uk-grid">
                      <Grid container spacing={1} style={{height: "100%"}} >
                        <Grid item xs={5}>
                          <Grid container spacing={1}>
                            <MarketDataChart data={this.state.depthdata.chartdata} height={(chartheight/2)-100}/>
                          </Grid>
                        </Grid>                
                        <Grid item xs={7}>
                          <EdgeForecastChart charttype="candles" heading="Price Chart" showheading={true}
                                  data={this.state.chartdata} height={(chartheight/2)-100} showmavs={true} />                     
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </td>
              </tr>
            )
          ]}
      </React.Fragment>
    );
  } 
}

export default SymbolEdgeRow