
import React, { PureComponent } from "react";
import LoginPage from "./auth_login_page";
import PasswordResetPage from "./auth_passwordreset";
import SignUpPage from "./auth_signup";
import VerifyPage from "./auth_verify";
import EdgeForecastApp from "../general/mainapp";
import ServerConnectionError from "../general/servererror"
import defaultusersettings from "../general/defaultsettings";

export default class Authentication extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dataendpoint: "https://api.edgeforecaster.com/",      
      cognitouser: null,
      user_type: null,
      userdetails: {},
      usersettings: [],
      // status: "SignUp",
      status: "SignIn",
      // status: "Welcome",
      loading: true
    };
  }
  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  async retriveUserDetails(userid) {
    let headers = new Headers();
    headers.append('Content-Type', 'text/html');    
    headers.append('Access-Control-Allow-Origin','*');
    headers.append('Origin','https://app.edgeforecaster.com/');
    
    console.log(this.state.dataendpoint+'getuser/'+userid)
    fetch(this.state.dataendpoint+'getuser/'+userid, { 
      method: 'get',      
      headers: headers,
      mode: 'cors'     
    }).then(response => response.json())
    .then((data) => {
      var oldState = this.state;
      var newState = {
        ...oldState,
        user_type: data.customertype,
        userdetails: data,        
        loading: false
      }      
      this.setState(newState)      
    }).catch((error) => {
      console.log("Fetch User Error");
      console.log(error)
      this.setState({status:"ServerError"})
    });
    

  }
  handleFormInput = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  addCognitoUser = user => {
    this.setState({ cognitouser: user });
  }
  addrole = role => {
    this.setState({ user_type:role });
  }
  addsites = sites => {
    this.setState({ user_sites:sites });
  }
  adduserdetails = userdetails => {
    this.setState({ userdetails:userdetails });
  }
  switchComponent = status => {
    this.setState({ status });
  };
  toggleLoading = loading => {
    this.setState({ loading });
  };
  authComponent = () => {
    switch (this.state.status) {
      case "SignUp":
        return (
          <SignUpPage
            switchComponent={this.switchComponent}
            handleFormInput={this.handleFormInput}
            inputs={this.state}
          />
        );       
      case "Verify":
        return (
          <VerifyPage
            switchComponent={this.switchComponent}
            handleFormInput={this.handleFormInput}
            inputs={this.state}
          />
        );       
      case "SignIn":
        return (
          <LoginPage
            loading={this.state.loading}
            switchComponent={this.switchComponent}
            adduser={this.addCognitoUser}
            addsites={this.addsites}
            addrole={this.addrole}
            adduserdetails={this.adduserdetails}
            toggleLoading={this.toggleLoading}
            handleFormInput={this.handleFormInput}
            inputs={this.state}
          />
        );        
      case "ResetPass":
        return (
          <PasswordResetPage
              loading={this.state.loading}
              switchComponent={this.switchComponent}
              adduser={this.addCognitoUser}
              addsites={this.addsites}
              addrole={this.addrole}
              adduserdetails={this.adduserdetails}
              toggleLoading={this.toggleLoading}
              handleFormInput={this.handleFormInput}
              inputs={this.state}
            />
      );        
      case "ServerError":
      return (
          <ServerConnectionError switchComponent={this.switchComponent} />
      );
      case "Welcome":        
        return <EdgeForecastApp authstate={this.state.cognitouser} userdetails={this.state.userdetails} loading={this.state.loading} />;
      default:
        return (
          <ServerConnectionError switchComponent={this.switchComponent} />
        );
    }
  };
  componentDidMount() {
    this.setState({loading: false})
    // this.retriveUserDetails('98bd0d1e-cc3a-47f4-9ac2-2efc6515401d')
  }
  
  render() {
    // console.log(this.state.userdetails)
    return (<div> {this.authComponent()}</div>);
    // return (<div> Test</div>);
  }
}
