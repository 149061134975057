import React from "react";
import Grid from '@material-ui/core/Grid';
import Chart from "react-apexcharts";
import HeadingWithInfo from "../general/headingwithinfo";
import useWindowDimensions from "../../utils/windowdimensions";
import "../../assets/css/main.css"

const forecastabilitydescription = "Forecastability is a measure out of 100 as to how accuracte the Edgeforecaster forecasts have been."

class ForecastabilityItem extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
        expanded: false,           
      }
    }
    
    render() {
        var chartdata = [
            {
                "name":"Short Term",
                "data":this.props.accuracychartseries.shortterm           
            },
            {
                "name":"Long Term",
                "data":this.props.accuracychartseries.longterm
            }]
       
        var chartHeight = 85;
        if(window.innerHeight>1280) { chartHeight=100; }

        return (
            <React.Fragment>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Grid container spacing={0} className="edgeforecast_predcontainer">
                            <Grid item xs={8}>
                                <HeadingWithInfo heading="Forecastability" headingclassname="edgeforecast_predheading" description={forecastabilitydescription} />
                            </Grid>
                            <Grid item xs={4}>
                                <div className="edgeforecast_preddata">{this.props.forecastability}</div>
                            </Grid>                            
                            <Grid item xs={12}>
                                <Chart series={chartdata} 
                                    options={this.props.accuracychartoptions} 
                                    type="heatmap" height={chartHeight}/>
                            </Grid>                           
                        </Grid>                          
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default ForecastabilityItem