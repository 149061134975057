import React from "react";
import { Helmet } from 'react-helmet'
import "react-pro-sidebar/dist/css/styles.css";
import "../../assets/css/main.css"

class LegalsPage extends React.Component {  
  state = { 
    usersettings: null,    
   }

  
  
  componentDidMount() {
    
  }
  
  render() {    
    const siteTitle = 'Legal'    
    return (
        <React.Fragment>
            <Helmet>
            <title>EdgeForecaster - {siteTitle}</title>
            <meta name="description" content="EdgeForecaster" />
            </Helmet>
            <div id="main" className="wrapper style1">
              <div className="container">          
                <div className="row gtr-150">            
                  <div className="col-12 col-12-medium imp-medium">
                    <section id="content">
                      <p>Legals Page</p>                    
                    </section>
                  </div>
                </div>
              </div>
            </div>
        </React.Fragment>
    );    
  }
}

export default LegalsPage