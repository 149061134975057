import React, { Component } from "react";
// import { Auth } from "aws-amplify";
import '../../assets/css/App.css';
import logo from '../../assets/images/logo.png';

export default class SignUpPage extends Component {
  
  handleSignUp = event => {
    event.preventDefault();
    const { username, email, password, phone_number } = this.props.inputs;
    console.log(username +"\t"+ password +"\t"+ email +"\t"+ phone_number)
    // Auth.signUp({
    //   username,
    //   password,
    //   attributes: {
    //     email, // optional
    //     phone_number // optional - E.164 number convention
    //     // other custom attributes
    //   },
    //   validationData: [] //optional
    // })
    //   .then(data => console.log(data))
    //   .then(()=>this.props.switchComponent("Verify")) // switches Sign Up to Verification
    //   .catch(err => console.log(err))
  };

  handleAlreadySignedUp = event => {
    this.props.switchComponent("SignIn")
  }

  render() {
    return (      
      <div className="login_page_container">
        <img src={logo} alt="Logo" className="login_page_logo"/>
        <form className="authentication__form">
          <div className="mb-4">
            <input type="text" name="username" value={this.props.username} placeholder="Username" onChange={this.props.handleFormInput} className="login_page_input"/>
          </div>
          <div className="mb-4">
            <input type="email" name="email" value={this.props.email} placeholder="Email" onChange={this.props.handleFormInput} className="login_page_input" />
            </div>
          <div className="mb-4">
            <input type="password" name="password" value={this.props.password} placeholder="Password" onChange={this.props.handleFormInput} className="login_page_input"/>
            </div>
          <div className="mb-4">
            <input type="text" name="phone_number" value={this.props.phone_number} placeholder="Phone Number" onChange={this.props.handleFormInput} className="login_page_input"/>
          </div>
          <div className="mb-8">
            
          </div>
          <div className="mb-4">
            <input type="submit" value="Sign Up" onClick={this.handleSignUp} className="login_page_button"/>
            
          </div>
          <div className="mb-4">
            <button onClick={this.handleAlreadySignedUp} className="login_page_button">Already Signed Up</button>
          </div>
        </form>
      </div>    
    );
  }
}