import React, { Component } from "react";
import { Grid } from '@material-ui/core';
import Spinner from 'react-spinner-material';
import '../../assets/css/App.css';
import logo from '../../assets/images/logo.png';

export default class PasswordResetPage extends Component {
  
  handleSignIn = event => {
    event.preventDefault();
    const { username, password } = this.props.inputs;
    this.props.toggleLoading(true)
    console.log(username +"\t"+ password)
    // Auth.signIn({ username, password })
    //   .then((user) => {
    //     // console.log(user) 
    //     this.props.adduser(user); 
    //   }).then(() => {
        
    //     this.props.toggleLoading(false)
    //     this.props.switchComponent("Welcome")        
    //   })
    //   .catch(err => console.log(err));      
  };
  handleForgotPassword = event => {
    this.props.switchComponent("SignUp")
  }

  render() {
    return (
      <div className="login_page_container">
        <div className='spinner_overlay_login' visible={this.props.loading} >
          <div className='spinner_container'>
            <Spinner size={120} spinnerColor={"#ffdf27"} spinnerWidth={4} visible={this.props.loading} /> 
          </div>
        </div>
        <Grid container spacing={1} >
          <Grid item xs={0} md={2} lg={3}/>
          <Grid item xs={12} md={8} lg={6} >
            <Grid container spacing={1} >
              <Grid item xs={12} >
                <Grid container spacing={1} >
                  <Grid item xs={0} md={2} lg={1}/>
                  <Grid item xs={12} md={10} lg={10}>
                    <img src={logo} alt="Logo" className="login_page_logo"/>
                  </Grid>
                  <Grid item xs={0} md={2} lg={1}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={0} md={1} lg={2}/>
              <Grid item xs={12} md={10} lg={8} >
                <form className="authentication__form">
                  <Grid container spacing={1}>
                    <Grid item xs={12} > 
                      <h3>Reset Your Password</h3>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} >
                      <input type="text" name="username" value={this.props.username} placeholder="Email" onChange={this.props.handleFormInput} className="login_page_input"/>
                    </Grid>                    
                    <Grid item xs={2}/>
                    <Grid item xs={8} >
                      <button type="submit" value="Login" onClick={this.handleSignIn} className="login_page_button">Request Password Reset</button>
                    </Grid>
                    <Grid item xs={2}/>                    
                  </Grid>
                </form>
              </Grid>
              <Grid item xs={0} md={1} lg={2}/>
            </Grid>
          </Grid>
          <Grid item xs={0} md={2} lg={3}/>
        </Grid>        
        
      </div>    
    );
  }
}